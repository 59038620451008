<template>
  <Vueform
    name="mainvueform"
    v-model="formCommon.formPage"
    :display-errors="true"
    validate-on="step|change"
    size="sm"
    :display-messages="true"
    add-class="vf-coach-comment"
    ref="vueform"
    @before-next-step="onBeforeNextStep"
  >
    <template #empty>
      <FormSteps  ref="formSteps">
        <FormStep
          name="page0"
          :elements="[
            'containerThongTin',
            'containerThongTinHanhChinh',
            'divider_thongtinhanhchinh',
            'containerTongTrang',
            'divider_tongtrang',
            'containerTienSuBenh',
            'divider_tiensubenh',
            'containerTienSuBenhXaHoi',
          ]"
          label="THÔNG TIN"
          :labels="{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}"
        />
        <FormStep
          name="page1"
          :elements="[
            'containerThongTin',
            'containerTinhThan',
            'divider_tinhthan',
            'containerVanDong',
            'divider_vandong',
            'containerDinhDuong',
          ]"
          label="HOẠT ĐỘNG & DINH DƯỠNG"
          :labels="{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}"
        />
        <!-- <FormStep
          name="page2"
          :elements="['containerThongTin', 'containerDinhDuong']"
          label="DINH DƯỠNG"
        /> -->
        <FormStep
          name="page2"
          :elements="[
            'containerThongTin',
            'containerDuongHuyet',
            'divide-chamsocbanchan',
            'containerBanChan',
            'divide-SuDungThuoc',
            'containerSuDungThuoc',
            'divide-HocTap',
            'containerHocTap',
          ]"
          label="KHẢ NĂNG TỰ CHĂM SÓC"
          :labels="{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}"
        />
        <FormStep
          name="page3"
          label="ĐỘNG LỰC & MỤC TIÊU"
          :elements="[
            'containerThongTin',
            'containerDLVMT'
          ]"
              :labels="{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}"
        />
      </FormSteps>

      <FormElements name="vueelement">
        <GroupElement name="containerThongTin">
          <ButtonElement
            name="button"
            @click="createPageOne"
            :loading="loading"
          >
            Submit
          </ButtonElement>
          <StaticElement
            name="h1"
            tag="h3"
            content="PHIẾU KẾ HOẠCH GIÁO DỤC VÀ HỖ TRỢ TỰ QUẢN LÝ BỆNH ĐTĐ (DSMES PLAN FORM)
"
            align="center"
          />

          <TextElement
            class="labeltext"
            name="courseName"
            label="Khóa"
            :columns="{
              container: 4,
            }"
          />

          <DateElement
            class="labeltext"
            name="executionDate"
            label="Ngày thực hiện"
            :columns="{
              container: 4,
            }"
          />

          <TextElement
            class="labeltext"
            name="executorName"
            label="Người thực hiện (Họ và tên)"
            :columns="{
              container: 4,
            }"
          />
          <ButtonElement
            name="buttonExpand"
            :loading="loading"
            @click="expandAll"
            :columns="{
              container: 4,
            }"
          >
            Expand All
          </ButtonElement>

          <ButtonElement
            name="buttonCollapse"
            :loading="loading"
            @click="collapseAll"
            :columns="{
              container: 4,
            }"
          >
            Collapse All
          </ButtonElement>

          <StaticElement name="divider_thongtinchung" tag="hr" />
        </GroupElement>

        <GroupElement name="containerThongTinHanhChinh">
          <StaticElement
            name="header_thong_tin_hanh_chinh"
            tag="h4"
            content="THÔNG TIN HÀNH CHÍNH"
            :columns="{ container: 3 }"
          />
          <div class="toggle-icon-container">
            <span @click="toggleThongTinHanhChinh">
              <inline-svg
                :src="
                  isShowThongTinHanhChinh
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>
        </GroupElement>

        <transition name="fade">
          <GroupElement
            name="container_16"
            v-if="isShowThongTinHanhChinh"
            class="border-group"
          >
            <TextElement
              name="fullName"
              label="Họ và tên"
              :columns="{
                container: 7,
              }"
            />
            <TextElement
              name="yearOfBirth"
              label="Năm sinh"
              input-type="number"
              :columns="{
                container: 3,
              }"
            />
            <SelectElement
              id="gender"
              name="gender"
              :items="genders"
              :search="true"
              :native="false"
              label="Giới tính"
              input-type="search"
              autocomplete="off"
              :columns="{
                container: 2,
              }"
            />
            <TextElement
              name="phoneNumber"
              label="Số điện thoại"
              :columns="{
                container: 6,
              }"
            />
            <TextElement
              name="residence"
              label="Nơi sống"
              :columns="{
                container: 3,
              }"
            />
            <TextElement
              name="job"
              label="Nghề nghiệp"
              :columns="{
                container: 3,
              }"
            />
            <SelectElement
              id="diseaseType"
              name="diseaseType"
              :items="diseasesOpts"
              :search="true"
              :native="false"
              label="Loại Đái tháo đường"
              input-type="search"
              autocomplete="off"
              :columns="{
                container: 6,
              }"
            />
            <TextElement
              name="diagnosedYear"
              label="Chẩn đoán từ năm"
              :columns="{
                container: 5,
              }"
            />
            <StaticElement
              name="p_9"
              tag="h4"
              content="KẾT QUẢ KHẢ NĂNG TỰ CHĂM SÓC"
              :columns="{
                container: 6,
              }"
            />
            <StaticElement
              name="p_10"
              tag="h4"
              :columns="{
                container: 6,
              }"
              content="KẾT QUẢ KHẢO SÁT KIẾN THỨC"
            />
            <GroupElement name="container4_1" class="border-group">
              <GroupElement
                name="column2_4"
                :columns="{
                  container: 6,
                }"
                label="Mức độ kiến thức"
              >
                <TextElement
                  name="knowledgePoint"
                  :columns="{
                    container: 8,
                  }"
                />
                <TextElement
                  name="knowledgeAverage"
                  label="Điểm  trung bình (/10)"
                  :columns="{
                    container: 8,
                  }"
                />
              </GroupElement>
              <GroupElement
                name="column4_1"
                :columns="{
                  container: 6,
                }"
              >
                <TextElement
                  name="selfCarePoint"
                  label="Mức độ Tự Chăm sóc"
                  :columns="{
                    container: 8,
                  }"
                />

                <TextElement
                  name="selfCareAverage"
                  label="Điểm  trung bình (/10)"
                  :columns="{
                    container: 8,
                  }"
                />
              </GroupElement>
            </GroupElement>
          </GroupElement>
        </transition>
        <!-- THÔNG TIN HÀNH CHÍNH END -->
        <StaticElement name="divider_thongtinhanhchinh" tag="hr" />
        <GroupElement
          :columns="{
            container: 9,
          }"
          name="containerTongTrang"
        >
          <StaticElement
            name="title_Tongtrang"
            tag="h4"
            content="TỔNG TRẠNG"
            :columns="{ container: 4 }"
          />
          <div class="toggle-icon-container-tongtrang" @click="toggleTongTrang">
            <inline-svg
              :src="
                isShowTongTrang
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
              "
            />
          </div>
        </GroupElement>
        <transition name="fade">
          <GroupElement
            v-if="isShowTongTrang"
            class="border-group"
            name="tongtrang"
          >
            <GroupElement
              class="border-group"
              name="column1"
              :columns="{
                container: 9,
              }"
            >
              <TextElement
                name="weight"
                label="Cân nặng (kg)"
                :columns="{
                  container: 4,
                }"
              />
              <TextElement
                name="height"
                label="Chiều cao (cm)"
                :columns="{
                  container: 4,
                }"
              />
              <TextElement
                name="bmi"
                label="BMI (1 kg/m2)"
                :columns="{
                  container: 4,
                }"
              />
              <TextElement name="statusTongTrang" label="Tổng trạng" />
            </GroupElement>
            <GroupElement
              name="issueTongTrangGroup"
              class="border-group"
              :columns="{
                container: 3,
              }"
            >
              <StaticElement
                name="title_issueTongTrang"
                tag="h4"
                content="VẤN ĐỀ CỦA KHÁCH HÀNG"
              />
              <EditorElement name="issueTongTrang" />
            </GroupElement>
          </GroupElement>
        </transition>
        <!-- TINH THAN -->
        <StaticElement name="divider_tongtrang" tag="hr" />

        <GroupElement name="containerTinhThan">
          <StaticElement
            name="h4_19"
            tag="h4"
            content="TINH THẦN"
            :columns="{
              container: 3,
            }"
          />
          <div class="toggle-icon-container-tinhthan" @click="toggleTinhThan">
            <inline-svg
              :src="
                isShowTinhThan
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
              "
            />
          </div>

          <transition name="fade">
            <GroupElement
              name="container2_6"
              class="border-group"
              v-if="isShowTinhThan"
            >
              <GroupElement
                name="column1"
                :columns="{
                  container: 9,
                }"
              >
                <GroupElement name="container2_7" class="border-group">
                  <GroupElement
                    name="column1"
                    :columns="{
                      container: 7,
                    }"
                  >
                    <StaticElement
                      name="title_sleep_problem"
                      tag="p"
                      content="Bạn có gặp vấn đề gì về giấc ngủ không (mất ngủ, ngủ không ngon giấc...)?"
                    />
                  </GroupElement>
                  <GroupElement
                    name="column2"
                    :columns="{
                      container: 5,
                    }"
                  >
                    <RadiogroupElement
                      name="sleepingProblem"
                      view="tabs"
                      :items="[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ]"
                    />
                  </GroupElement>
                </GroupElement>
                <GroupElement name="container2_8" class="border-group">
                  <GroupElement name="container2_9" class="border-group">
                    <GroupElement
                      name="column1"
                      :columns="{
                        container: 2,
                      }"
                    >
                      <StaticElement
                        name="title_sleepingProblemExpand"
                        tag="p"
                        content="Vấn đề giấc ngủ"
                        :columns="{
                          container: 10,
                        }"
                      />
                    </GroupElement>
                    <GroupElement
                      name="column2"
                      :columns="{
                        container: 10,
                      }"
                    >
                      <GroupElement name="column2_6">
                        <CheckboxElement
                          name="insomnia"
                          :columns="{
                            container: 4,
                          }"
                          text="Mất ngủ"
                        />
                        <CheckboxElement
                          name="poorSleepQuality"
                          text="Ngủ không ngon "
                          :columns="{
                            container: 4,
                          }"
                        />
                        <CheckboxElement
                          name="difficultyFallingAsleep"
                          text="Khó bắt đầu giấc ngủ"
                          :columns="{
                            container: 4,
                          }"
                        />
                        <CheckboxElement
                          name="frequentWaking"
                          text="Hay bị thức giấc "
                          :columns="{
                            container: 4,
                          }"
                        />
                        <CheckboxElement
                          name="useSleepingPills"
                          text="Dùng thuốc ngủ "
                          :columns="{
                            container: 4,
                          }"
                        />
                      </GroupElement>
                      <GroupElement
                        name="column2_8"
                        :columns="{
                          container: 12,
                        }"
                      >
                        <TextElement
                          name="nightSleepingTime"
                          label="Giờ ngủ tối(h)"
                          :columns="{
                            default: {
                              container: 6,
                            },
                          }"
                        />
                        <TextElement
                          name="nightMiddaySleepTime"
                          label="Giờ ngủ trưa(h)"
                          :columns="{
                            default: {
                              container: 6,
                            },
                          }"
                        />
                        <TextElement
                          name="totalSleepTime"
                          label="Tổng thời gian ngủ trong ngày(h)"
                          :columns="{
                            default: {
                              container: 12,
                            },
                          }"
                        />
                      </GroupElement>
                    </GroupElement>
                  </GroupElement>
                  <GroupElement name="container2_10" class="border-group">
                    <GroupElement
                      class="border-group"
                      name="column2_9"
                      :columns="{
                        container: 12,
                      }"
                    >
                      <GroupElement name="column2_10">
                        <StaticElement
                          name="title_anxiety"
                          tag="p"
                          content="Lo lắng"
                          :columns="{
                            container: 3,
                          }"
                        />
                        <RadiogroupElement
                          name="problemWorry"
                          view="tabs"
                          :items="[
                            {
                              value: false,
                              label: 'Không',
                            },
                            {
                              value: true,
                              label: 'Có',
                            },
                          ]"
                          :columns="{
                            container: 3,
                          }"
                        />
                        <TextElement
                          :disabled="problemWorry"
                          name="problemWorryInput"
                          placeholder="Vấn đề lo lắng"
                          :columns="{
                            default: {
                              container: 4,
                            },
                          }"
                        />
                      </GroupElement>
                      <GroupElement
                        name="column2_12"
                        :columns="{
                          container: 12,
                        }"
                      >
                        <StaticElement
                          name="p_12"
                          tag="p"
                          content="Căng thẳng"
                          :columns="{
                            container: 3,
                          }"
                        />
                        <RadiogroupElement
                          name="problemStress"
                          view="tabs"
                          :items="[
                            {
                              value: false,
                              label: 'Không',
                            },
                            {
                              value: true,
                              label: 'Có',
                            },
                          ]"
                          :columns="{
                            container: 3,
                          }"
                        />
                        <TextElement
                          :disabled="problemStress"
                          name="problemStressInput"
                          placeholder="Vấn đề căng thẳng"
                          :columns="{
                            default: {
                              container: 4,
                            },
                          }"
                        />
                      </GroupElement>
                    </GroupElement>
                    <GroupElement
                      name="column2_11"
                      :columns="{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }"
                    >
                    </GroupElement>
                    <GroupElement
                      name="column2_13"
                      class="border-group"
                      :columns="{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }"
                    >
                      <StaticElement
                        name="p_13"
                        tag="p"
                        content="Các biện pháp đang sử dụng để giảm căng thẳng, giảm lo lắng, rối loạn giấc ngủ (Nếu có)"
                      />
                      <GroupElement
                        name="column2_14"
                        :columns="{
                          default: {
                            container: 10,
                          },
                          lg: {
                            container: 12,
                          },
                        }"
                      >
                        <CheckboxElement
                          name="optRelax"
                          :columns="{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          }"
                          text="Thư giãn"
                        />
                        <CheckboxElement
                          name="optMeditation"
                          text="Thiền"
                          :columns="{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          }"
                        />
                        <CheckboxElement
                          name="optBreath"
                          text="Hít thở"
                          :columns="{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          }"
                        />
                        <TextElement
                          name="otherReduceStressInput"
                          label="Khác"
                          style="margin-top: -15px"
                          :columns="{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 4,
                            },
                          }"
                        />
                      </GroupElement>
                    </GroupElement>
                    <GroupElement
                      class="border-group"
                      name="column2_16"
                      :columns="{
                        default: {
                          container: 12,
                        },
                      }"
                    >
                      <StaticElement
                        name="p_14"
                        tag="p"
                        style="font-weight: bold"
                        content="Tôn giáo, tín ngưỡng"
                        :columns="{
                          default: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="optTrustNo"
                        :columns="{
                          default: {
                            container: 3,
                          },
                        }"
                        text="Không"
                      />
                      <CheckboxElement
                        name="optTrustBuddha"
                        text="Phật giáo"
                        :columns="{
                          default: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="optTrusInChrist"
                        text="Thiên chúa giáo"
                        :columns="{
                          default: {
                            container: 3,
                          },
                        }"
                      />
                      <StaticElement
                        name="title_space"
                        :columns="{
                          default: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="optTrustIslam"
                        text="Hồi giáo"
                        :columns="{
                          default: {
                            container: 2,
                          },
                        }"
                      />
                      <TextElement
                        style="margin-top: -15px"
                        name="otherTrustInput"
                        label="Khác"
                        :columns="{
                          default: {
                            container: 4,
                          },
                        }"
                      />
                    </GroupElement>

                    <GroupElement
                      name="column2_17"
                      :columns="{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }"
                    >
                      <GroupElement
                        class="border-group"
                        name="column1_10"
                        :columns="{
                          container: 12,
                        }"
                      >
                        <StaticElement
                          name="p_15"
                          tag="p"
                          content="Khác"
                          :columns="{
                            container: 2,
                          }"
                        />
                        <TextElement
                          name="text_11"
                          :columns="{
                            default: {
                              container: 10,
                            },
                          }"
                        />
                      </GroupElement>
                    </GroupElement>
                  </GroupElement>
                </GroupElement>
              </GroupElement>
              <GroupElement
                name="column2"
                :columns="{
                  container: 3,
                }"
              >
                <StaticElement
                  name="h4_21"
                  tag="h4"
                  :columns="{
                    container: 12,
                  }"
                  content="VẤN ĐỀ CỦA KHÁCH HÀNG"
                />
                <EditorElement name="issueTinhThan" />
              </GroupElement>
            </GroupElement>
          </transition>
        </GroupElement>
        <StaticElement name="divider_tinhthan" tag="hr" />

        <GroupElement name="containerVanDong">
          <StaticElement
            name="h4_22"
            tag="h4"
            content="VẬN ĐỘNG"
            :columns="{
              container: 3,
            }"
          />
          <div class="toggle-icon-container-vandong" @click="toggleVanDong">
            <inline-svg
              :src="
                isShowVanDong
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
              "
            />
          </div>

          <transition name="fade">
            <GroupElement
              name="container2_12 "
              class="border-group"
              v-if="isShowVanDong"
            >
              <GroupElement
                name="column1_8"
                :columns="{
                  container: 9,
                }"
                class="border-group"
              >
                <TextElement
                  placeholder="Nhập số ngày"
                  label="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn tham gia một môn thể dục thể thao cụ thể (như là bơi lội, đi bộ, đạp xe…) không bao gồm việc nhà hoặc công việc thường ngày? (ngày)"
                  name="activityOneWeek"
                  :columns="{
                    lg: {
                      container: 12,
                    },
                  }"
                />
                <TextElement
                  placeholder="Nhập số ngày"
                  name="activityOneWeek30min"
                  label="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn vận động thể chất ít nhất 30 phút (tổng số phút của các hoạt động liên tục, bao gồm đi bộ)? (ngày)"
                  :columns="{
                    default: {
                      container: 12,
                    },
                  }"
                />

                <GroupElement name="container2_15" class="border-group">
                  <StaticElement
                    name="p_17"
                    tag="p"
                    content="Vận động thể chất"
                    :columns="{
                      container: 3,
                    }"
                  />

                  <RadiogroupElement
                    name="activityWorkout"
                    view="tabs"
                    :items="[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có (Nếu có hỏi tiếp những câu hỏi bên dưới)',
                      },
                    ]"
                    :columns="{
                      container: 9,
                    }"
                  />
                </GroupElement>
                <GroupElement name="container2_16" class="border-group">
                  <GroupElement
                    name="column1_13"
                    :columns="{
                      default: {
                        container: 2,
                      },
                      lg: {
                        container: 3,
                      },
                    }"
                  >
                    <StaticElement
                      name="physicalActivityType"
                      tag="p"
                      content="Loại hình vận động"
                      :columns="{
                        container: 10,
                      }"
                    />
                  </GroupElement>
                  <GroupElement
                    name="column2_23"
                    :columns="{
                      default: {
                        container: 10,
                      },
                      lg: {
                        container: 9,
                      },
                    }"
                  >
                    <GroupElement name="column2_24">
                      <CheckboxElement
                        name="yoga"
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                        text="Yoga"
                      />
                      <CheckboxElement
                        name="swimming"
                        text="Bơi lội"
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="walking"
                        text="Đi bộ"
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="qigong"
                        text="Dưỡng sinh "
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="gym"
                        text="Gym "
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="cycling"
                        text="Đạp xe"
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="running"
                        text="Chạy bộ"
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                      <CheckboxElement
                        name="otherPhysicalActivity"
                        text="Khác "
                        :columns="{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }"
                      />
                    </GroupElement>
                  </GroupElement>
                  <GroupElement
                    name="column2_25"
                    :columns="{
                      default: {
                        container: 10,
                      },
                      lg: {
                        container: 12,
                      },
                    }"
                  >
                    <GroupElement name="column1_14">
                      <StaticElement
                        name="p_19"
                        tag="p"
                        content="Ngồi xem tivi/làm việc "
                        :columns="{
                          container: 3,
                        }"
                      />
                      <TextElement
                        placeholder="giờ/ngày"
                        name="text_37"
                        :columns="{
                          default: {
                            container: 8,
                          },
                        }"
                      />
                      <StaticElement
                        name="p_22"
                        tag="p"
                        content="Khác"
                        :columns="{
                          container: 2,
                        }"
                      />
                      <TextElement
                        name="text_40"
                        :columns="{
                          default: {
                            container: 9,
                          },
                        }"
                      />
                    </GroupElement>
                  </GroupElement>
                </GroupElement>
              </GroupElement>
              <GroupElement
                name="container2_17"
                :columns="{
                  default: {
                    container: 3,
                  },
                }"
              >
                <StaticElement
                  name="h4_23"
                  tag="h4"
                  :columns="{
                    container: 12,
                  }"
                  content="VẤN ĐỀ CỦA KHÁCH HÀNG"
                />
                <EditorElement
                  name="textarea_4"
                  :columns="{
                    default: {
                      container: 12,
                    },
                  }"
                />
              </GroupElement>
            </GroupElement>
          </transition>
        </GroupElement>
        <StaticElement />
        <StaticElement name="divider_vandong" tag="hr" />

        <GroupElement name="containerTienSuBenh">
          <StaticElement
            name="title_tiensubenh"
            tag="h4"
            content="TIỀN SỬ BỆNH"
            :columns="{
              container: 3,
            }"
          />
          <div
            class="toggle-icon-container-tiensubenh"
            @click="toggleTienSuBenh"
          >
            <inline-svg
              :src="
                isShowTienSuBenh
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
              "
            />
          </div>
        </GroupElement>

        <transition name="fade">
          <GroupElement
            name="medicalHistoryGroup"
            class="border-group"
            v-if="isShowTienSuBenh"
          >
            <GroupElement
              name="column1"
              :columns="{
                container: 9,
              }"
            >
              <GroupElement name="containerMEDICAL" class="border-group">
                <StaticElement
                  name="medicalHistoryQuestion"
                  class="labeltext"
                  content="Bạn có các bệnh dưới đây không? ( KQKS )"
                  :columns="{
                    container: 5,
                  }"
                />
                <TextElement
                  name="medicalHistoryInput"
                  :columns="{
                    container: 7,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_1" class="border-group">
                <StaticElement
                  name="title_hypoglycemiaQuestion"
                  class="labeltext"
                  input-type="number"
                  content="Hạ đường huyết"
                  :columns="{
                    container: 3,
                  }"
                />

                <RadiogroupElement
                  id="hypoglycemia"
                  name="hypoglycemia"
                  view="tabs"
                  :items="[
                    {
                      value: 0,
                      label: 'Chưa bao giờ',
                    },
                    {
                      value: 1,
                      label: 'Thỉnh thoảng',
                    },
                    {
                      value: 2,
                      label: 'Thường xuyên',
                    },
                  ]"
                />
              </GroupElement>
              <GroupElement name="container_3" class="border-group">
                <StaticElement
                  name="title_hypoglycemiaLevelQuestion"
                  class="labeltext"
                  content="Đường huyết lúc hạ đường huyết"
                  :columns="{
                    container: 6,
                  }"
                />
                <TextElement
                  name="hypoglycemiaNumber"
                  input-type="number"
                  label="Chỉ số"
                  :columns="{
                    container: 3,
                  }"
                />
                <SelectElement
                  id="hypoglycemiaUnit"
                  name="hypoglycemiaUnit"
                  :items="[
                    {
                      value: 0,
                      label: 'mg/dl',
                    },
                    {
                      value: 1,
                      label: 'mmol/L',
                    },
                  ]"
                  :search="true"
                  :native="false"
                  label="Đơn vị"
                  input-type="search"
                  autocomplete="off"
                  :columns="{
                    container: 3,
                  }"
                />
              </GroupElement>
              <GroupElement class="border-group" name="container_99">
                <GroupElement
                  :columns="{
                    container: 3,
                  }"
                >
                  <StaticElement
                    name="title_symptom"
                    class="labeltext"
                    content="Biểu hiện"
                    :columns="{
                      container: 4,
                    }"
                  />
                </GroupElement>
                <GroupElement
                  :columns="{
                    container: 8,
                  }"
                >
                  <CheckboxElement
                    name="sweating"
                    text="Đổ mồ hôi"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="shaking"
                    text="Run tay"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="anxiety"
                    text="Lo lắng"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="dizziness"
                    text="Chóng mặt"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="hunger"
                    text="Đói"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />

                  <CheckboxElement
                    name="rapidHeartbeat"
                    text="Tim đập nhanh"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="blurredVision"
                    text="Nhìn mờ"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="fatigue"
                    text="Mệt mỏi"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="headache"
                    text="Đau đầu"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <CheckboxElement
                    name="irritability"
                    text="Cáu gắt"
                    :true-value="1"
                    :false-value="0"
                    :columns="{
                      container: 3,
                    }"
                  />
                </GroupElement>
              </GroupElement>
              <GroupElement class="border-group">
                <EditorElement
                  id="treatmentMethod"
                  label="Cách xử trí"
                  class="labeltext"
                  name="treatmentMethod"
                  :columns="{
                    container: 12,
                  }"
                />
                <TextElement
                  class="labeltext"
                  label="Khác"
                  name="otherQuestionTienSuBenh"
                  :columns="{
                    container: 12,
                  }"
                />
              </GroupElement>
            </GroupElement>
            <GroupElement
              class="border-group"
              :columns="{
                container: 3,
              }"
            >
              <StaticElement
                name="medicalHistoryQuestion"
                tag="h4"
                content="VẤN ĐỀ CỦA KHÁCH HÀNG"
              />
              <EditorElement name="issueHistoryMedical" />
            </GroupElement>
          </GroupElement>
        </transition>

        <StaticElement name="divider_tiensubenh" tag="hr" />

        <GroupElement name="containerTienSuBenhXaHoi">
          <StaticElement
            name="title_TienSuBenhXH"
            tag="h4"
            content="TIỀN SỬ BỆNH XÃ HỘI"
            :columns="{
              container: 3,
            }"
          />
          <div
            class="toggle-icon-container-tiensubenhxahoi"
            @click="toggleTienSuBenhXaHoi"
          >
            <inline-svg
              :src="
                isShowTienSuBenhXaHoi
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
              "
            />
          </div>
        </GroupElement>
        <GroupElement class="border-group" v-if="isShowTienSuBenhXaHoi">
          <GroupElement>
            <GroupElement
              name="column1_2"
              :columns="{
                container: 9,
              }"
            >
              <GroupElement name="container_8" class="border-group">
                <StaticElement
                  name="labeltext_alcohol"
                  class="labeltext"
                  content="Bạn có sử dụng rượu/bia không? ( KQKS )"
                  :columns="{
                    container: 5,
                  }"
                />
                <TextElement
                  name="alcoholUse"
                  :columns="{
                    container: 7,
                  }"
                  placeholder=" (KQKS)"
                />
              </GroupElement>
              <GroupElement name="container_9" class="border-group">
                <StaticElement
                  name="title_frequency"
                  class="labeltext"
                  content="Tần suất:"
                  :columns="{
                    container: 2,
                  }"
                />
                <TextElement
                  name="frequency"
                  :columns="{
                    container: 5,
                  }"
                  placeholder=" (KQKS)"
                />
              </GroupElement>
              <GroupElement name="container_14" class="border-group">
                <StaticElement
                  name="title_amount"
                  class="labeltext"
                  content="Số lượng:"
                  :columns="{
                    container: 2,
                  }"
                />
                <TextElement
                  input-type="number"
                  name="amount"
                  :columns="{
                    container: 5,
                  }"
                  placeholder=" (KQKS)"
                />
                <StaticElement
                  name="title_unit"
                  content=" (ml/lần) "
                  :columns="{
                    container: 2,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_11" class="border-group">
                <StaticElement
                  name="title_smoking"
                  class="labeltext"
                  content="Hiện tại bạn có hút thuốc lá không?"
                  :columns="{
                    container: 6,
                  }"
                />
                <RadiogroupElement
                  name="smokingStatus"
                  view="tabs"
                  :items="[
                    {
                      value: 1,
                      label: 'Không',
                    },
                    {
                      value: 0,
                      label: 'Có',
                    },
                  ]"
                />
                <StaticElement
                  name="title_unit"
                  content=" "
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_12" class="border-group">
                <StaticElement
                  name="title_average_smoking"
                  class="labeltext"
                  content="Trung bình Bạn hút bao nhiêu điếu một ngày "
                  :columns="{
                    container: 7,
                  }"
                />
                <TextElement
                  style="margin-top: -10px"
                  name="averageCigarettesPerDay"
                  :columns="{
                    container: 2,
                  }"
                />
                <StaticElement
                  name="title_unit_inday"
                  content="điếu/ngày"
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_10" class="border-group">
                <StaticElement
                  name="title_mostly_smoking"
                  class="labeltext"
                  content="Khi nào Bạn thấy mình sử dụng thuốc lá nhiều nhất trong ngày?"
                  :columns="{
                    container: 7,
                  }"
                />
                <TextElement
                  style="margin-top: -10px"
                  name="whenMostlySmoke"
                  :columns="{
                    container: 2,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_13" class="border-group">
                <CheckboxElement
                  class="labeltext"
                  name="livingAlone"
                  text=" Sống một mình"
                  :columns="{
                    container: 4,
                  }"
                />

                <TextElement
                  class="labeltext"
                  style="margin-top: -10px"
                  name="livingWithSomeone"
                  label="Sống chung với:"
                  :columns="{
                    container: 6,
                  }"
                />
                <StaticElement
                  name="title_ismainpillar"
                  class="labeltext"
                  content="Trụ cột chính trong gia đình? "
                  :columns="{
                    container: 4,
                  }"
                />
                <RadiogroupElement
                  name="mainPillar"
                  view="tabs"
                  :items="[
                    {
                      value: 1,
                      label: 'Không',
                    },
                    {
                      value: 0,
                      label: 'Có',
                    },
                  ]"
                />
                <TextElement
                  class="labeltext"
                  label="Khác"
                  name="otherBenhXh"
                  :columns="{
                    container: 12,
                  }"
                />
              </GroupElement>
            </GroupElement>
            <GroupElement
              class="border-group"
              :columns="{
                container: 3,
              }"
            >
              <StaticElement
                name="HistoryMedical"
                tag="h4"
                content="VẤN ĐỀ CỦA KHÁCH HÀNG"
              />
              <EditorElement name="issueHistoryMedicalXh" />
            </GroupElement>
          </GroupElement>
        </GroupElement>

        <GroupElement name="containerDuongHuyet">
          <StaticElement
            name="titleDuongHuyet"
            tag="h4"
            content="THEO DÕI ĐƯỜNG HUYẾT"
            :columns="{ container: 3 }"
          />
          <div class="toggle-icon-container-duonghuyet">
            <span @click="toggleDuongHuyet">
              <inline-svg
                :src="
                  isShowDuongHuyet
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>
        </GroupElement>
        <transition name="fade">
          <GroupElement name="DuongHuyetExpand" v-if="isShowDuongHuyet">
            <GroupElement name="container2_19" class="border-group">
              <GroupElement
                name="column1_18"
                :columns="{
                  container: 9,
                }"
              >
                <GroupElement name="container_4">
                  <GroupElement name="container2_20" class="border-group">
                    <StaticElement
                      name="h4_26"
                      :columns="{
                        container: 7,
                      }"
                      tag="p"
                      content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn đo đường huyết?
"
                    />
                    <TextElement
                      placeholder="ngày"
                      name="text_12"
                      :columns="{
                        container: 5,
                      }"
                    />
                  </GroupElement>
                </GroupElement>
                <GroupElement name="container_17" class="border-group">
                  <StaticElement
                    name="h4_28"
                    tag="p"
                    content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn đo đường huyết theo số lần bác sĩ của bạn khuyến nghị?"
                    :columns="{
                      container: 7,
                    }"
                  />
                  <TextElement
                    placeholder="ngày"
                    name="text_13"
                    :columns="{
                      container: 5,
                    }"
                  />
                </GroupElement>
                <GroupElement name="container_18" class="border-group">
                  <TextElement
                    name="text_70"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    }"
                    label="HbA1c (%)"
                  />
                  <DateElement
                    name="date_1"
                    label="Ngày xét nghiệm"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    display-format="DD-MM-YYYY"
                  />

                  <TextElement
                    name="text_35"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    }"
                    label="Đường huyết đói "
                  />
                  <DateElement
                    name="date_2"
                    label="Ngày đo"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    display-format="DD-MM-YYYY"
                  />

                  <TextElement
                    name="text_36"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    }"
                    label="Đường huyết 2 giờ sau ăn"
                  />
                  <DateElement
                    name="date_3"
                    label="Ngày đo"
                    :columns="{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    display-format="DD-MM-YYYY"
                  />

                  <TextElement name="text_52" label="Khác" />
                </GroupElement>
              </GroupElement>
              <GroupElement
                name="column2_22"
                :columns="{
                  container: 3,
                }"
              >
                <StaticElement
                  name="h4_25"
                  tag="h4"
                  :columns="{
                    container: 12,
                  }"
                  content="VẤN ĐỀ CỦA KHÁCH HÀNG"
                />
                <EditorElement name="textarea_5" />
              </GroupElement>
            </GroupElement>
          </GroupElement>
        </transition>
        <StaticElement name="divide-chamsocbanchan" tag="hr" />

        <GroupElement name="containerBanChan">
          <StaticElement
            name="titlecsBanChan"
            tag="h4"
            content="CHĂM SÓC BÀN CHÂN "
            :columns="{ container: 3 }"
          />
          <div class="toggle-icon-container-chamsocbanchan">
            <span @click="togglechamSocBanChan">
              <inline-svg
                :src="
                  isShowBanChan
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>
        </GroupElement>
        <transition name="fade">
          <GroupElement name="BanChanExpand" v-if="isShowBanChan">
            <GroupElement name="container2_19" class="border-group">
              <GroupElement
                name="column1_18"
                :columns="{
                  container: 9,
                }"
              >
                <GroupElement name="container_4">
                  <GroupElement name="container2_20" class="border-group">
                    <StaticElement
                      name="h4_26"
                      :columns="{
                        container: 7,
                      }"
                      tag="p"
                      content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn kiểm tra bàn chân của mình? (Kiểm tra lòng bàn chân, cảm giác của bàn chân, khe bàn chân,…)"
                    />
                    <TextElement
                      placeholder="ngày"
                      name="text_12"
                      :columns="{
                        container: 5,
                      }"
                    />
                  </GroupElement>
                </GroupElement>
                <GroupElement name="container_17" class="border-group">
                  <StaticElement
                    name="h4_28"
                    tag="p"
                    content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn kiểm tra bên trong giày của mình? (Có vật lạ,…)"
                    :columns="{
                      container: 7,
                    }"
                  />
                  <TextElement
                    placeholder="ngày"
                    name="text_13"
                    :columns="{
                      container: 5,
                    }"
                  />
                </GroupElement>
                <GroupElement name="container_23" class="border-group">
                  <StaticElement
                    name="title_ismainpillar"
                    content="Khám biến chứng bàn chân Đái tháo đường"
                    :columns="{
                      container: 4,
                    }"
                  />
                  <RadiogroupElement
                    name="mainPillar"
                    view="tabs"
                    :items="[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ]"
                    :columns="{
                      container: 8,
                    }"
                  />
                  <TextElement name="text_69" label="Khác" />
                </GroupElement>
              </GroupElement>
              <GroupElement
                name="column2_22"
                :columns="{
                  container: 3,
                }"
              >
                <StaticElement
                  name="h4_25"
                  tag="h4"
                  :columns="{
                    container: 12,
                  }"
                  content="VẤN ĐỀ CỦA KHÁCH HÀNG"
                />
                <EditorElement name="textarea_5" />
              </GroupElement>
            </GroupElement>
          </GroupElement>
        </transition>

        <StaticElement name="divide-SuDungThuoc" tag="hr" />

        <GroupElement name="containerSuDungThuoc">
          <StaticElement
            name="titleSuDungThuoc"
            tag="h4"
            content="SỬ DỤNG THUỐC"
            :columns="{ container: 3 }"
          />
          <div class="toggle-icon-container-sudungthuoc">
            <span @click="toggleSuDungThuoc">
              <inline-svg
                :src="
                  isShowSuDungThuoc
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>
        </GroupElement>
        <transition name="fade">
          <GroupElement
            name="ExpandSuDungThuoc"
            v-if="isShowSuDungThuoc"
            class="border-group"
          >
            <GroupElement
              name="column1"
              :columns="{
                default: {
                  container: 9,
                },
                lg: {
                  container: 9,
                },
              }"
            >
              <GroupElement name="container_20">
                <GroupElement
                  name="container2_24"
                  :columns="{
                    lg: {
                      container: 12,
                    },
                  }"
                >
                  <StaticElement
                    name="h4_36"
                    tag="p"
                    content="Thuốc tiểu đường đang sử dụng"
                    :columns="{
                      default: {
                        container: 4,
                      },
                    }"
                  />
                  <RadiogroupElement
                    name="problemFood"
                    view="tabs"
                    :items="[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ]"
                    :columns="{
                      container: 6,
                    }"
                  />
                </GroupElement>
                <GroupElement
                  name="container2_37"
                  :columns="{
                    lg: {
                      container: 12,
                    },
                  }"
                >
                  <StaticElement
                    name="h4_33"
                    tag="p"
                    content="Chi tiết thuốc"
                    :columns="{
                      default: {
                        container: 4,
                      },
                    }"
                  />
                  <MultifileElement
                    name="multiImage"
                    label="Thêm hình ảnh"
                    view="image"
                    accept="image/*"
                    :file="{
                      rules: [
                        'mimetypes:image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/tiff',
                      ],
                    }"
                    :columns="{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 3,
                      },
                    }"
                  />
                  <MultifileElement
                    name="multifile"
                    label="Thêm tài liệu"
                    :columns="{
                      lg: {
                        container: 3,
                      },
                      default: {
                        container: 6,
                      },
                    }"
                    :urls="{}"
                  />
                </GroupElement>
                <GroupElement
                  name="container2_38"
                  :columns="{
                    lg: {
                      container: 12,
                    },
                  }"
                >
                  <StaticElement
                    name="h4_34"
                    tag="p"
                    content="Thuốc tiểu đường đang sử dụng"
                    :columns="{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 4,
                      },
                    }"
                  />
                  <GroupElement
                    name="container_19"
                    :columns="{
                      default: {
                        container: 9,
                      },
                      lg: {
                        container: 8,
                      },
                    }"
                  >
                    <CheckboxElement
                      name="checkbox_77"
                      :columns="{
                        default: {
                          container: 5,
                        },
                        lg: {
                          container: 6,
                        },
                      }"
                      text="Không muốn dùng thuốc"
                    />
                    <CheckboxElement
                      name="checkbox_81"
                      :columns="{
                        default: {
                          container: 7,
                        },
                        lg: {
                          container: 6,
                        },
                      }"
                      text="Quên thường xuyên"
                    />
                    <CheckboxElement
                      name="checkbox_83"
                      :columns="{
                        default: {
                          container: 5,
                        },
                        lg: {
                          container: 6,
                        },
                      }"
                      text="Thỉnh thoảng quên  "
                    />
                    <CheckboxElement
                      name="checkbox_82"
                      :columns="{
                        default: {
                          container: 6,
                        },
                        lg: {
                          container: 6,
                        },
                      }"
                      text="Tuân thủ tốt"
                    />
                  </GroupElement>
                  <TextElement
                    name="text_71"
                    label="Khó khăn khi dùng thuốc "
                  />
                </GroupElement>
              </GroupElement>
            </GroupElement>
            <GroupElement
              name="column2"
              :columns="{
                default: {
                  container: 3,
                },
                lg: {
                  container: 3,
                },
              }"
            >
              <StaticElement
                name="h4_31"
                tag="h4"
                :columns="{
                  container: 12,
                }"
                content="VẤN ĐỀ CỦA KHÁCH HÀNG"
              />
              <EditorElement name="textarea_7" />
            </GroupElement>
          </GroupElement>
        </transition>
        <StaticElement name="divide-HocTap" tag="hr" />

        <GroupElement name="containerHocTap">
          <StaticElement
            name="titleSuDungThuoc"
            tag="h4"
            content="KHẢ NĂNG HỌC TẬP"
            :columns="{ container: 3 }"
          />
          <div class="toggle-icon-container-hoctap">
            <span @click="toggleHocTap">
              <inline-svg
                :src="
                  isShowHocTap
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>
        </GroupElement>
        <GroupElement
          name="containerHocTapExpand"
          v-if="isShowHocTap"
          class="border-group"
        >
          <GroupElement
            name="column1_23"
            :columns="{
              default: {
                container: 9,
              },
              lg: {
                container: 9,
              },
            }"
          >
            <GroupElement name="container_26">
              <GroupElement
                name="container2_26"
                :columns="{
                  lg: {
                    container: 12,
                  },
                }"
              >
                <StaticElement
                  name="h4_37"
                  tag="p"
                  content="Bạn học tốt nhất thông qua:"
                  :columns="{
                    default: {
                      container: 3,
                    },
                    lg: {
                      container: 4,
                    },
                  }"
                />
                <CheckboxElement
                  name="checkbox_48"
                  :columns="{
                    default: {
                      container: 1,
                    },
                    lg: {
                      container: 2,
                    },
                  }"
                  text="Nghe "
                />
                <CheckboxElement
                  name="checkbox_49"
                  text="Nhìn"
                  :columns="{
                    default: {
                      container: 1,
                    },
                    lg: {
                      container: 2,
                    },
                  }"
                />
                <CheckboxElement
                  name="checkbox_60"
                  text="Hình ảnh"
                  :columns="{
                    default: {
                      container: 2,
                    },
                    lg: {
                      container: 2,
                    },
                  }"
                />
                <CheckboxElement
                  name="checkbox_59"
                  text="Làm/thực hành"
                  :columns="{
                    default: {
                      container: 2,
                    },
                    lg: {
                      container: 2,
                    },
                  }"
                />
              </GroupElement>
              <GroupElement
                name="container2_40"
                :columns="{
                  lg: {
                    container: 12,
                  },
                }"
              >
                <StaticElement
                  name="h4_39"
                  tag="p"
                  content="Rào cản/khó khăn trong học tập:"
                  :columns="{
                    default: {
                      container: 3,
                    },
                    lg: {
                      container: 4,
                    },
                  }"
                />
                <GroupElement
                  name="container_27"
                  :columns="{
                    default: {
                      container: 9,
                    },
                    lg: {
                      container: 8,
                    },
                  }"
                >
                  <CheckboxElement
                    name="checkbox_50"
                    :columns="{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Rào cản ngôn ngữ "
                  />
                  <CheckboxElement
                    name="checkbox_56"
                    :columns="{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Giảm thị lực"
                  />
                  <CheckboxElement
                    name="checkbox_57"
                    :columns="{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Trình độ học vấn"
                  />
                  <CheckboxElement
                    name="checkbox_58"
                    :columns="{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Giảm thính lực"
                  />
                  <CheckboxElement
                    name="checkbox_62"
                    :columns="{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Khác biệt văn hóa "
                  />
                  <CheckboxElement
                    name="checkbox_61"
                    :columns="{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 6,
                      },
                    }"
                    text="Khả năng sử dụng CN "
                  />
                </GroupElement>

                <TextElement name="text_38" label="Khác" />
              </GroupElement>
            </GroupElement>
          </GroupElement>
          <GroupElement
            name="column2_36"
            :columns="{
              default: {
                container: 3,
              },
              lg: {
                container: 3,
              },
            }"
          >
            <StaticElement
              name="h4_35"
              tag="h4"
              :columns="{
                container: 12,
              }"
              content="VẤN ĐỀ CỦA KHÁCH HÀNG"
            />
            <EditorElement name="textarea_8" />
          </GroupElement>
        </GroupElement>

        <GroupElement name="containerDinhDuong">
          <StaticElement
            name="h4_52"
            tag="h4"
            content="DINH DƯỠNG"
            :columns="{
              container: 3,
            }"
          />

          <div class="toggle-icon-container-dinhduong">
            <span @click="toggleDinhDuong">
              <inline-svg
                :src="
                  isShowDinhDuong
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                "
              />
            </span>
          </div>

          <GroupElement
            name="container2_28"
            v-if="isShowDinhDuong"
            class="border-group"
          >
            <GroupElement
              name="column1_26"
              :columns="{
                container: 9,
              }"
            >
              <GroupElement name="container2_29">
                <GroupElement
                  name="column1_27"
                  :columns="{
                    container: 12,
                  }"
                  class="border-group"
                >
                  <StaticElement
                    name="h4_55"
                    tag="p"
                    content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn theo chế độ ăn uống lành mạnh?
"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <TextElement
                    placeholder="Nhập số ngày"
                    name="text_54"
                    :columns="{
                      container: 3,
                    }"
                  />
                </GroupElement>
              </GroupElement>
              <GroupElement name="container_22" class="border-group">
                <StaticElement
                  name="h4_57"
                  tag="p"
                  content="Trung bình trong THÁNG QUA, Có bao nhiêu ngày mỗi tuần Bạn ăn theo chế độ ăn của mình?"
                  :columns="{
                    container: 9,
                  }"
                />
                <TextElement
                  placeholder="Nhập số ngày"
                  name="text_55"
                  :columns="{
                    container: 3,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_23" class="border-group">
                <StaticElement
                  name="h4_59"
                  tag="p"
                  content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn 3 suất trái cây và hơn 3 suất rau củ?"
                  :columns="{
                    container: 9,
                  }"
                />
                <TextElement
                  placeholder="Nhập số ngày"
                  name="text_56"
                  :columns="{
                    container: 3,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_24" class="border-group">
                <StaticElement
                  name="h4_61"
                  tag="p"
                  content="Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn thức ăn có lượng béo cao như thịt đỏ, hoặc các sản phẩm từ sữa nguyên kem?"
                  :columns="{
                    container: 9,
                  }"
                />
                <TextElement
                  placeholder="Nhập số ngày"
                  name="text_57"
                  :columns="{
                    container: 3,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_25" class="border-group">
                <StaticElement
                  name="h4_63"
                  tag="h4"
                  content="Dị ứng thức ăn"
                  :columns="{
                    container: 12,
                  }"
                />
                <GroupElement name="container2_33" class="border-group">
                  <RadiogroupElement
                    name="problemFood"
                    view="tabs"
                    :items="[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ]"
                    :columns="{
                      container: 12,
                    }"
                  />
                </GroupElement>
                <StaticElement
                  name="h4_64"
                  tag="h4"
                  content="Mô tả một ngày ăn uống"
                  :columns="{
                    container: 12,
                  }"
                />
                <GroupElement name="container2_34" class="border-group">
                  <StaticElement
                    name="h4_65"
                    tag="p"
                    content="Sáng"
                    :columns="{
                      container: 3,
                    }"
                  />

                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <StaticElement
                    name="h4_66"
                    tag="p"
                    content="Phụ 1"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <StaticElement
                    name="h4_67"
                    tag="p"
                    content="Trưa"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <StaticElement
                    name="h4_68"
                    tag="p"
                    content="Phụ 2"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <StaticElement
                    name="h4_69"
                    tag="p"
                    content="Chiều"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                  <StaticElement
                    name="h4_70"
                    tag="p"
                    content="Phụ 3"
                    :columns="{
                      container: 3,
                    }"
                  />
                  <TextElement
                    name="text_58"
                    :columns="{
                      container: 9,
                    }"
                  />
                </GroupElement>
                <StaticElement
                  name="h4_71"
                  tag="h4"
                  content="Thói quen ăn uống "
                  :columns="{
                    container: 12,
                  }"
                />
                <GroupElement name="container2_35" class="border-group">
                  <GroupElement name="column1_33">
                    <StaticElement
                      name="h4_72"
                      tag="p"
                      content="Số bữa chính"
                      :columns="{
                        container: 2,
                      }"
                    />
                    <TextElement
                      name="text_63"
                      :columns="{
                        container: 9,
                      }"
                    />
                    <StaticElement
                      name="h4_73"
                      tag="p"
                      content="Số bữa phụ"
                      :columns="{
                        container: 2,
                      }"
                    />
                    <TextElement
                      name="text_64"
                      :columns="{
                        container: 9,
                      }"
                    />
                    <StaticElement
                      name="h4_74"
                      tag="p"
                      content="Ăn vặt"
                      :columns="{
                        container: 4,
                      }"
                    />
                    <RadiogroupElement
                      name="sleepingProblem"
                      view="tabs"
                      :items="[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ]"
                      :columns="{
                        container: 7,
                      }"
                    />
                    <TextElement
                      placeholder="Ghi chú ghi rõ món ăn vặt"
                      name="text_65"
                      :columns="{
                        container: 12,
                      }"
                    />

                    <StaticElement
                      name="h4_75"
                      tag="p"
                      content="Ăn đúng giờ"
                      :columns="{
                        container: 4,
                      }"
                    />
                    <RadiogroupElement
                      name="sleepingProblem"
                      view="tabs"
                      :items="[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ]"
                      :columns="{
                        container: 7,
                      }"
                    />
                    <StaticElement
                      name="h4_76"
                      tag="p"
                      content="Ăn ngoài"
                      :columns="{
                        container: 4,
                      }"
                    />
                    <RadiogroupElement
                      name="sleepingProblem"
                      view="tabs"
                      :items="[
                        {
                          value: 0,
                          label: 'Không',
                        },
                        {
                          value: 1,
                          label: 'Thỉnh thoảng',
                        },
                        {
                          value: 2,
                          label: 'Thường xuyên',
                        },
                      ]"
                      :columns="{
                        container: 8,
                      }"
                    />
                    <StaticElement
                      name="h4_76"
                      tag="p"
                      content="Ăn ngoài"
                      :columns="{
                        container: 4,
                      }"
                    />
                    <RadiogroupElement
                      name="sleepingProblem"
                      view="tabs"
                      :items="[
                        {
                          value: 0,
                          label: 'Không',
                        },
                        {
                          value: 1,
                          label: 'Thỉnh thoảng',
                        },
                        {
                          value: 2,
                          label: 'Thường xuyên',
                        },
                      ]"
                      :columns="{
                        container: 8,
                      }"
                    />
                    <StaticElement
                      name="h4_78"
                      tag="p"
                      content="Lượng nước uống trong ngày ( lít/ngày
 )"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <TextElement
                      name="text_66"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <StaticElement
                      name="h4_79"
                      tag="p"
                      content="Loại nước uống trong ngày :"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <TextElement
                      name="text_67"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <StaticElement
                      name="h4_80"
                      tag="p"
                      content="Mức kcal/ngày theo khuyến nghị "
                      :columns="{
                        container: 6,
                      }"
                    />
                    <TextElement
                      name="text_68"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <StaticElement
                      name="h4_81"
                      tag="p"
                      content="Nguyên tắc Thực đơn"
                      :columns="{
                        container: 6,
                      }"
                    />
                    <CheckboxElement
                      name="checkbox_76"
                      text="Gửi thực đơn cho KH "
                      :columns="{
                        container: 6,
                      }"
                    />
                    <TextElement name="text_69" label="Khác" />
                  </GroupElement>
                </GroupElement>
              </GroupElement>
            </GroupElement>
            <GroupElement
              name="column2_45"
              :columns="{
                container: 3,
              }"
            >
              <StaticElement
                name="h4_54"
                tag="h4"
                :columns="{
                  container: 12,
                }"
                content="VẤN ĐỀ CỦA KHÁCH HÀNG"
              />
              <EditorElement name="textarea_6" />
            </GroupElement>
          </GroupElement>
        </GroupElement>
        <GroupElement name="containerDLVMT" class="border-group">
          <StaticElement name="h2" tag="h4" content="ĐỘNG LỰC " />

          <GroupElement name="containerDL" class="border-group">
            <EditorElement name="textarea_9" />
          </GroupElement>
          <StaticElement name="divider_DLMT" tag="hr" />

          <StaticElement
            name="h2"
            tag="h4"
            content="TRỞ NGẠI (KHÓ KHĂN) (khi tham gia chương trình và mục tiêu đạt được)"
          />

          <GroupElement name="containerTN" class="border-group">
            <EditorElement name="textarea_9" />
          </GroupElement>
          <StaticElement name="divider_DLMT" tag="hr" />

          <StaticElement name="h2" tag="h4" content="MỨC ĐỘ TỰ TIN" />

          <GroupElement name="containerMDTT" class="border-group">
            <EditorElement name="textarea_9" />
          </GroupElement>
          <StaticElement name="divider_DLMT" tag="hr" />

          <StaticElement name="h2" tag="h4" content="VẤN ĐỀ CỦA KHÁCH HÀNG" />

          <GroupElement name="containerVDKH" class="border-group">
            <EditorElement name="textarea_9" />
          </GroupElement>
          <StaticElement name="divider_DLMT" tag="hr" />

          <GroupElement
            name="containerMuctieu"
            :columns="{
              container: 12,
            }"
          >
            <StaticElement
              name="title_containerMuctieu"
              tag="h4"
              content="MỤC TIÊU"
              :columns="{
                container: 2,
              }"
            />
            <div class="toggle-icon-container-muctieu">
              <span @click="toggleMuctieu">
                <inline-svg
                  :src="
                    isShowMuctieu
                      ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                      : '/media/svg/icons/Neolex/Basic/circle-plus.svg'
                  "
                />
              </span>
            </div>
          </GroupElement>
          <GroupElement
            name="container2_41"
            class="border-group"
            v-if="isShowMuctieu"
          >
            <StaticElement
              name="h2_6"
              tag="h4"
              content="MỤC TIÊU CHƯƠNG TRÌNH"
            />
            <GroupElement name="container2_445" class="border-group">
              <GroupElement name="container_hba1c">
                <StaticElement
                  name="h2_6"
                  tag="p"
                  class="labeltext"
                  content="HbA1c"
                  :columns="{
                    container: 2,
                  }"
                />
                <RadiogroupElement
                  name="optionsHba1c"
                  view="tabs"
                  :items="[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ]"
                  :columns="{
                    container: 3,
                  }"
                />
                <TextElement
                  name="hbA1cInput"
                  placeholder="%"
                  input-type="number"
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_weight">
                <StaticElement
                  name="h2_6"
                  tag="p"
                  class="labeltext"
                  content="Cân nặng"
                  :columns="{
                    container: 2,
                  }"
                />
                <RadiogroupElement
                  name="optionsWeight"
                  view="tabs"
                  :items="[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ]"
                  :columns="{
                    container: 3,
                  }"
                />
                <TextElement
                  name="weightInput"
                  placeholder="kg"
                  input-type="number"
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_knowledge">
                <StaticElement
                  name="h2_6"
                  tag="p"
                  class="labeltext"
                  content="Kiến thức"
                  :columns="{
                    container: 2,
                  }"
                />
                <RadiogroupElement
                  name="optionsKnowledge"
                  view="tabs"
                  :items="[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ]"
                  :columns="{
                    container: 3,
                  }"
                />
                <TextElement
                  name="yearOfBirth"
                  placeholder="điểm"
                  input-type="number"
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
              <GroupElement name="container_kntcs">
                <StaticElement
                  name="h2_6"
                  tag="p"
                  class="labeltext"
                  content="Khả năng tự CS"
                  :columns="{
                    container: 2,
                  }"
                />
                <RadiogroupElement
                  name="optionsKntcs"
                  view="tabs"
                  :items="[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ]"
                  :columns="{
                    container: 3,
                  }"
                />
                <TextElement
                  name="yearOfBirth"
                  placeholder="điểm"
                  input-type="number"
                  :columns="{
                    container: 1,
                  }"
                />
              </GroupElement>
            </GroupElement>

            <StaticElement name="h2_6" tag="h4" content="MỤC TIÊU CỤ THỂ" />
            <MatrixElement
              name="table_3"
              :input-type="{
                type: 'text',
              }"
              :presets="['matrix-table']"
              :cols="[
                {
                  label: 'Mục tiêu thay đổi lối sống',
                  value: 'mục_tiêu_thay_đổi_lối_sống',
                },
                {
                  label: 'Kế hoạch hành động ',
                  value: 'kế_hoạch_hành_động_',
                },
              ]"
              :rows="1"
              min="1"
              max="10"
            />
            <MatrixElement
              name="table"
              :input-type="{
                type: 'text',
              }"
              :presets="['matrix-table']"
              :cols="[
                {
                  label: 'Mục tiêu thay đổi lối sống',
                  value: 'mục_tiêu_thay_đổi_lối_sống',
                },
                {
                  label: 'Kế hoạch hành động ',
                  value: 'kế_hoạch_hành_động_',
                },
              ]"
              rows="1"
              min="1"
              max="10"
            />
          </GroupElement>
          <GroupElement name="container2_44">
            <GroupElement
              name="container2_46"
              :columns="{
                lg: {
                  container: 12,
                },
              }"
            >
              <StaticElement name="divider_DLMT" tag="hr" />

              <StaticElement name="h2_1" tag="h4" content="HÀNH ĐỘNG" />
              <EditorElement name="editor" />
              <MatrixElement
                name="table_1"
                :input-type="{
                  type: 'text',
                }"
                :presets="['matrix-table']"
                :cols="[
                  {
                    label: 'Mục tiêu thay đổi lối sống',
                    value: 'mục_tiêu_thay_đổi_lối_sống',
                  },
                  {
                    label: 'Kế hoạch hành động ',
                    value: 'kế_hoạch_hành_động_',
                  },
                ]"
                rows="1"
                min="1"
                max="10"
              />
            </GroupElement>
          </GroupElement>
          <GroupElement name="container2_47">
            <GroupElement
              name="container2_48"
              :columns="{
                lg: {
                  container: 12,
                },
              }"
            >
              <StaticElement
                name="h2_4"
                tag="h4"
                content="NHẬN XÉT CHUNG
"
              />
              <EditorElement name="editor_1" />
              <MatrixElement
                name="table_2"
                :input-type="{
                  type: 'text',
                }"
                :presets="['matrix-table']"
                :cols="[
                  {
                    label: 'Mục tiêu thay đổi lối sống',
                    value: 'mục_tiêu_thay_đổi_lối_sống',
                  },
                  {
                    label: 'Kế hoạch hành động ',
                    value: 'kế_hoạch_hành_động_',
                  },
                ]"
                rows="1"
                min="1"
                max="10"
              />
            </GroupElement>
          </GroupElement>
        </GroupElement>
      </FormElements>

      <FormStepsControls />
    </template>
  </Vueform>
</template>
<script>
import { DISEASES } from '@/view/pages/customer_receives/CustomerReceiveEnum';
export default {
  props: {
    name: {
      type: String,
      default: 'pageOne',
    },
  },
  computed: {
    diseasesOpts() {
      return [
        { label: 'Tiền đái tháo đường', value: DISEASES.TIEN_DAI_THAO_DUONG },
        { label: 'Đái tháo đường típ 1', value: DISEASES.DAI_THAO_DUONG_TIP_1 },
        { label: 'Đái tháo đường típ 2', value: DISEASES.DAI_THAO_DUONG_TIP_2 },
        {
          label: 'Đái tháo đường thai kì',
          value: DISEASES.DAI_THAO_DUONG_THAI_KI,
        },
        { label: 'Tăng huyết áp', value: DISEASES.TANG_HUYET_AP },
        { label: 'Béo phì', value: DISEASES.BEO_PHI },
        { label: 'Khác', value: DISEASES.KHAC },
      ];
    },
    symptomString() {
      const { formPage } = this.formCommon;
      return JSON.stringify({
        sweating: formPage.sweating,
        shaking: formPage.shaking,
        anxiety: formPage.anxiety,
        dizziness: formPage.dizziness,
        hunger: formPage.hunger,
        rapidHeartbeat: formPage.rapidHeartbeat,
        blurredVision: formPage.blurredVision,
        fatigue: formPage.fatigue,
        headache: formPage.headache,
        irritability: formPage.irritability,
        other: formPage.other,
      });
    },
  },
  data() {
    return {
      loading: false,
      isShowThongTinHanhChinh: false,
      isShowTongTrang: false,
      isShowTienSuBenh: false,
      isShowTienSuBenhXaHoi: false,
      isShowTinhThan: false,
      isShowVanDong: false,
      isShowDinhDuong: false,
      isShowDuongHuyet: false,
      isShowBanChan: false,
      isShowSuDungThuoc: false,
      isShowHocTap: false,
      isShowMuctieu: false,
      diseaseType: null,
      dataSymptom: null,

      formCommon: {
        formPage: {
          id: null,
          courseId: null,
          courseName: null,
          executorName: null,
          fullName: null,
          yearOfBirth: null,
          gender: null,
          phoneNumber: null,
          residence: null,
          job: null,
          diagnosedYear: null,
          knowledgePoint: null,
          knowledgeAverage: null,
          selfCarePoint: null,
          selfCareAverage: null,
          weight: null,
          height: null,
          bmi: null,
          statusTongTrang: null,
          issueTongTrang: null,
          executionDate: null,
          hypoglycemiaFrequency: null,

          // Thu set name vueform
          medicalHistoryInput: null,
          hypoglycemia: null,
          hypoglycemiaNumber: null,
          hypoglycemiaUnit: null,
          symptom: null,
          treatmentMethod: null,
          otherBenh: null,
          issueHistoryMedical: null,
          alcoholUse: null,
          frequency: null,
          amount: null,
          smokingStatus: null,
          averageCigarettesPerDay: null,
          whenMostlySmoke: null,
          livingAlone: null,
          livingWithSomeone: null,
          mainPillar: null,
          otherBenhXh: null,
          issueHistoryMedicalXh: null,
          //checkbox
          sweating: false,
          shaking: false,
          anxiety: false,
          dizziness: false,
          hunger: false,
          rapidHeartbeat: false,
          blurredVision: false,
          fatigue: false,
          headache: false,
          irritability: false,

          //checkboxTinhthan
          insomnia: false,
          poorSleepQuality: false,
          difficultyFallingAsleep: false,
          frequentWaking: false,
          useSleepingPills: false,
          sleepingProblemExpand: null,
          nightSleepingTime: null,
          nightMiddaySleepTime: null,
          totalSleepTime: null,
          //checkcondition
          problemWorry: false,
          problemStress: false,
          problemStressInput: null,
          problemWorryInput: null,

          //ReduceStress
          reduceStress: null,
          optRelax: false,
          optMeditation: false,
          optBreath: false,
          otherReduceStressInput: null,

          //option Trust
          optTrustNo: false,
          optTrustBuddha: false,
          optTrusInChrist: false,
          optTrustIslam: false,
          otherTrustInput: null,
          trustBelieve: null,

          //Workout
          // activityWorkout: false,
        },
      },

      table_3: [
        {
          mục_tiêu_thay_đổi_lối_sống: null,
          kế_hoạch_hành_động_: null,
        },
      ],
      editor: null,
      editor_1: null,
      genders: [
        { label: 'Nam', value: '0' },
        { label: 'Nữ', value: '1' },
      ],
    };
  },

  methods: {
    async updateSymptomString(form) {
      this.formCommon.formPage.symptom = JSON.stringify({
        sweating: form.sweating,
        shaking: form.shaking,
        anxiety: form.anxiety,
        dizziness: form.dizziness,
        hunger: form.hunger,
        rapidHeartbeat: form.rapidHeartbeat,
        blurredVision: form.blurredVision,
        fatigue: form.fatigue,
        headache: form.headache,
        irritability: form.irritability,
        other: form.other,
      });

      // Only update if the value has changed
    },
    goToNextStep() {
      const formSteps = this.$refs.formSteps;
      if (formSteps) {
        this.collapseAll();
      //  formSteps.complete();
        formSteps.next();
      }
    },

    // https://vueform.com/recipes/submit-data-on-form-steps



//     goToNextStep() {
//   const formSteps = this.$refs.formSteps;
//   if (formSteps) {
//     this.collapseAll();
//     formSteps.validate().then((valid) => {
//       if (valid) {
//         formSteps.complete(); // Mark current step as complete
//         formSteps.next();     // Move to next step (this will only happen if validation passed)
//       }
//     });
//   }
// }

    goToPreviousStep() {
      const formSteps = this.$refs.formSteps;
      if (formSteps) {
        formSteps.prev();
      }
    },

    onBeforeNextStep(step) {
      // You can perform any checks or operations here before moving to the next step
      console.log('Moving to step:', step.name);

      // If you want to prevent moving to the next step, you can return false
      // return false;

      // If you want to allow moving to the next step, don't return anything or return true
      return true;
    },
    expandAll() {
      this.isShowThongTinHanhChinh = true;
      this.isShowTongTrang = true;
      this.isShowTienSuBenh = true;
      this.isShowTienSuBenhXaHoi = true;
      // this.isShowTinhThan = true;
      // this.isShowVanDong = true;
      // this.isShowDinhDuong = true;
      // this.isShowDuongHuyet = true;
    },
    collapseAll() {
      this.isShowThongTinHanhChinh = false;
      this.isShowTongTrang = false;
      this.isShowTienSuBenh = false;
      this.isShowTienSuBenhXaHoi = false;
      this.isShowTinhThan = false;
      this.isShowVanDong = false;
      this.isShowDinhDuong = false;
      this.isShowDuongHuyet = false;
    },

    toggleThongTinHanhChinh() {
      this.isShowThongTinHanhChinh = !this.isShowThongTinHanhChinh;
    },
    toggleTongTrang() {
      this.isShowTongTrang = !this.isShowTongTrang;
    },
    toggleTienSuBenh() {
      this.isShowTienSuBenh = !this.isShowTienSuBenh;
    },
    toggleTinhThan() {
      this.isShowTinhThan = !this.isShowTinhThan;
    },
    toggleVanDong() {
      this.isShowVanDong = !this.isShowVanDong;
    },
    toggleDinhDuong() {
      this.isShowDinhDuong = !this.isShowDinhDuong;
    },
    toggleDuongHuyet() {
      this.isShowDuongHuyet = !this.isShowDuongHuyet;
    },
    togglechamSocBanChan() {
      this.isShowBanChan = !this.isShowBanChan;
    },
    toggleTienSuBenhXaHoi() {
      this.isShowTienSuBenhXaHoi = !this.isShowTienSuBenhXaHoi;
    },
    toggleSuDungThuoc() {
      this.isShowSuDungThuoc = !this.isShowSuDungThuoc;
    },
    toggleHocTap() {
      this.isShowHocTap = !this.isShowHocTap;
    },
    toggleMuctieu() {
      this.isShowMuctieu = !this.isShowMuctieu;
    },
    async convertDateToTimeStamp() {
      const date = new Date(this.formCommon.formPage.executionDate);
      date.setHours(0, 0, 0, 0); // Set time components to zero
      const executionDate = Math.floor(date.getTime() / 1000);
      return executionDate;
    },

    async createPageOne() {
      this.loading = true;
      this.$store.commit('context/setLoading', true);

      try {
        //    console.log("this.dataSymptom2",this.dataSymptom)

        // this.formCommon.formPage.symptom = JSON.stringify({
        //   sweating: this.formCommon.formPage.sweating,
        //   shaking: this.formCommon.formPage.shaking,
        //   anxiety: this.formCommon.formPage.anxiety,
        //   dizziness: this.formCommon.formPage.dizziness,
        //   hunger: this.formCommon.formPage.hunger,
        //   rapidHeartbeat: this.formCommon.formPage.rapidHeartbeat,
        //   blurredVision: this.formCommon.formPage.blurredVision,
        //   fatigue: this.formCommon.formPage.fatigue,
        //   headache: this.formCommon.formPage.headache,
        //   irritability: this.formCommon.formPage.irritability,
        //   other: this.formCommon.formPage.other,
        // });

        const formData = new FormData();
        formData.append('CourseName', this.formCommon.formPage.courseName);
        formData.append('ExecutorName', this.formCommon.formPage.executorName);
        formData.append('FullName', this.formCommon.formPage.fullName);
        formData.append('YearOfBirth', this.formCommon.formPage.yearOfBirth);
        formData.append('Gender', this.formCommon.formPage.gender);
        formData.append('PhoneNumber', this.formCommon.formPage.phoneNumber);
        formData.append('Residence', this.formCommon.formPage.residence);
        formData.append('Job', this.formCommon.formPage.job);
        formData.append('DiseaseType', this.formCommon.formPage.diseaseType);
        formData.append(
          'DiagnosedYear',
          this.formCommon.formPage.diagnosedYear,
        );
        formData.append(
          'KnowledgePoint',
          this.formCommon.formPage.knowledgePoint,
        );
        formData.append(
          'KnowledgeAverage',
          this.formCommon.formPage.knowledgeAverage,
        );
        formData.append(
          'SelfCarePoint',
          this.formCommon.formPage.selfCarePoint,
        );
        formData.append(
          'SelfCareAverage',
          this.formCommon.formPage.selfCareAverage,
        );
        formData.append('weight', this.formCommon.formPage.weight);
        formData.append('height', this.formCommon.formPage.height);
        formData.append('bmi', this.formCommon.formPage.bmi);
        formData.append(
          'StatusTongTrang',
          this.formCommon.formPage.statusTongTrang,
        );
        formData.append(
          'IssueTongTrang',
          this.formCommon.formPage.issueTongTrang,
        );
        formData.append('ExecutionDate', await this.convertDateToTimeStamp());

        /// page 2

        formData.append('hypoglycemia', this.formCommon.formPage.hypoglycemia);
        formData.append(
          'HypoglycemiaNumber',
          this.formCommon.formPage.hypoglycemiaNumber,
        );
        formData.append(
          'HypoglycemiaUnit',
          this.formCommon.formPage.hypoglycemiaUnit,
        );

        await this.updateSymptomString(this.dataSymptom);
        formData.append('symptom', this.formCommon.formPage.symptom);
        formData.append(
          'treatmentMethod',
          this.formCommon.formPage.treatmentMethod,
        );
        //  formData.append('otherBenh', this.formCommon.formPage.otherBenh);
        formData.append(
          'IssueHistoryMedical',
          this.formCommon.formPage.issueHistoryMedical,
        );
        formData.append('alcoholUse', this.formCommon.formPage.alcoholUse);
        formData.append('frequency', this.formCommon.formPage.frequency);
        formData.append('amount', this.formCommon.formPage.amount);
        formData.append(
          'smokingStatus',
          this.formCommon.formPage.smokingStatus,
        );
        formData.append(
          'averageCigarettesPerDay',
          this.formCommon.formPage.averageCigarettesPerDay,
        );
        formData.append(
          'whenMostlySmoke',
          this.formCommon.formPage.whenMostlySmoke,
        );
        formData.append('livingAlone', this.formCommon.formPage.livingAlone);
        formData.append(
          'livingWithSomeone',
          this.formCommon.formPage.livingWithSomeone,
        );
        formData.append('mainPillar', this.formCommon.formPage.mainPillar);
        formData.append('otherBenhXh', this.formCommon.formPage.otherBenhXh);
        formData.append(
          'issueHistoryMedicalXh',
          this.formCommon.formPage.issueHistoryMedicalXh,
        );
        formData.append(
          'medicalHistoryInput',
          this.formCommon.formPage.medicalHistoryInput,
        );

        // Make API call
        const response = await this.$api.post('/DsmesInformation', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data.error) {
          throw new Error(response.data.error.message);
        }

        // Handle successful response
        this.$toastr.s({
          title: 'Thành công!',
          msg: 'Thông tin đã được thêm thành công.',
        });
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi !',
          msg: error.message || 'Có lỗi xảy ra khi tạo thông tin',
        });
      } finally {
        this.loading = false;
        this.$store.commit('context/setLoading', false);
        this.goToNextStep();
      }
    },
  },
  watch: {
    'formCommon.formPage': {
      handler(newFormPage) {
        this.dataSymptom = newFormPage;
      },
      deep: true,
    },
  },
};
</script>

<style>
.vf-coach-comment *,
.vf-coach-comment *:before,
.vf-coach-comment *:after,
.vf-coach-comment:root {
  --vf-primary: #07bf9b;
  --vf-primary-darker: #06ac8b;
  --vf-color-on-primary: #ffffff;
  --vf-danger: #ef4444;
  --vf-danger-lighter: #fee2e2;
  --vf-success: #10b981;
  --vf-success-lighter: #d1fae5;
  --vf-gray-50: #f9fafb;
  --vf-gray-100: #f3f4f6;
  --vf-gray-200: #e5e7eb;
  --vf-gray-300: #d1d5db;
  --vf-gray-400: #9ca3af;
  --vf-gray-500: #6b7280;
  --vf-gray-600: #4b5563;
  --vf-gray-700: #374151;
  --vf-gray-800: #1f2937;
  --vf-gray-900: #111827;
  --vf-dark-50: #efefef;
  --vf-dark-100: #dcdcdc;
  --vf-dark-200: #bdbdbd;
  --vf-dark-300: #a0a0a0;
  --vf-dark-400: #848484;
  --vf-dark-500: #737373;
  --vf-dark-600: #393939;
  --vf-dark-700: #323232;
  --vf-dark-800: #262626;
  --vf-dark-900: #191919;
  --vf-ring-width: 2px;
  --vf-ring-color: #07bf9b66;
  --vf-link-color: var(--vf-primary);
  --vf-link-decoration: inherit;
  --vf-font-size: 1rem;
  --vf-font-size-sm: 0.875rem;
  --vf-font-size-lg: 1rem;
  --vf-font-size-small: 0.875rem;
  --vf-font-size-small-sm: 0.8125rem;
  --vf-font-size-small-lg: 0.875rem;
  --vf-font-size-h1: 2.125rem;
  --vf-font-size-h1-sm: 2.125rem;
  --vf-font-size-h1-lg: 2.125rem;
  --vf-font-size-h2: 1.875rem;
  --vf-font-size-h2-sm: 1.875rem;
  --vf-font-size-h2-lg: 1.875rem;
  --vf-font-size-h3: 1.5rem;
  --vf-font-size-h3-sm: 1.5rem;
  --vf-font-size-h3-lg: 1.5rem;
  --vf-font-size-h4: 1.25rem;
  --vf-font-size-h4-sm: 1.25rem;
  --vf-font-size-h4-lg: 1.25rem;
  --vf-font-size-h1-mobile: 1.5rem;
  --vf-font-size-h1-mobile-sm: 1.5rem;
  --vf-font-size-h1-mobile-lg: 1.5rem;
  --vf-font-size-h2-mobile: 1.25rem;
  --vf-font-size-h2-mobile-sm: 1.25rem;
  --vf-font-size-h2-mobile-lg: 1.25rem;
  --vf-font-size-h3-mobile: 1.125rem;
  --vf-font-size-h3-mobile-sm: 1.125rem;
  --vf-font-size-h3-mobile-lg: 1.125rem;
  --vf-font-size-h4-mobile: 1rem;
  --vf-font-size-h4-mobile-sm: 1rem;
  --vf-font-size-h4-mobile-lg: 1rem;
  --vf-font-size-blockquote: 1rem;
  --vf-font-size-blockquote-sm: 0.875rem;
  --vf-font-size-blockquote-lg: 1rem;
  --vf-line-height: 1.5rem;
  --vf-line-height-sm: 1.25rem;
  --vf-line-height-lg: 1.5rem;
  --vf-line-height-small: 1.25rem;
  --vf-line-height-small-sm: 1.125rem;
  --vf-line-height-small-lg: 1.25rem;
  --vf-line-height-headings: 1.2;
  --vf-line-height-headings-sm: 1.2;
  --vf-line-height-headings-lg: 1.2;
  --vf-line-height-blockquote: 1.5rem;
  --vf-line-height-blockquote-sm: 1.25rem;
  --vf-line-height-blockquote-lg: 1.5rem;
  --vf-letter-spacing: 0px;
  --vf-letter-spacing-sm: 0px;
  --vf-letter-spacing-lg: 0px;
  --vf-letter-spacing-small: 0px;
  --vf-letter-spacing-small-sm: 0px;
  --vf-letter-spacing-small-lg: 0px;
  --vf-letter-spacing-headings: 0px;
  --vf-letter-spacing-headings-sm: 0px;
  --vf-letter-spacing-headings-lg: 0px;
  --vf-letter-spacing-blockquote: 0px;
  --vf-letter-spacing-blockquote-sm: 0px;
  --vf-letter-spacing-blockquote-lg: 0px;
  --vf-gutter: 1rem;
  --vf-gutter-sm: 0.5rem;
  --vf-gutter-lg: 1rem;
  --vf-min-height-input: 2.375rem;
  --vf-min-height-input-sm: 2.125rem;
  --vf-min-height-input-lg: 2.875rem;
  --vf-py-input: 0.375rem;
  --vf-py-input-sm: 0.375rem;
  --vf-py-input-lg: 0.625rem;
  --vf-px-input: 0.75rem;
  --vf-px-input-sm: 0.5rem;
  --vf-px-input-lg: 0.875rem;
  --vf-py-btn: 0.375rem;
  --vf-py-btn-sm: 0.375rem;
  --vf-py-btn-lg: 0.625rem;
  --vf-px-btn: 0.875rem;
  --vf-px-btn-sm: 0.75rem;
  --vf-px-btn-lg: 1.25rem;
  --vf-py-btn-small: 0.25rem;
  --vf-py-btn-small-sm: 0.25rem;
  --vf-py-btn-small-lg: 0.375rem;
  --vf-px-btn-small: 0.625rem;
  --vf-px-btn-small-sm: 0.625rem;
  --vf-px-btn-small-lg: 0.75rem;
  --vf-py-group-tabs: 0.375rem;
  --vf-py-group-tabs-sm: 0.375rem;
  --vf-py-group-tabs-lg: 0.625rem;
  --vf-px-group-tabs: 0.75rem;
  --vf-px-group-tabs-sm: 0.5rem;
  --vf-px-group-tabs-lg: 0.875rem;
  --vf-py-group-blocks: 0.75rem;
  --vf-py-group-blocks-sm: 0.625rem;
  --vf-py-group-blocks-lg: 0.875rem;
  --vf-px-group-blocks: 1rem;
  --vf-px-group-blocks-sm: 1rem;
  --vf-px-group-blocks-lg: 1rem;
  --vf-py-tag: 0px;
  --vf-py-tag-sm: 0px;
  --vf-py-tag-lg: 0px;
  --vf-px-tag: 0.4375rem;
  --vf-px-tag-sm: 0.4375rem;
  --vf-px-tag-lg: 0.4375rem;
  --vf-py-slider-tooltip: 0.125rem;
  --vf-py-slider-tooltip-sm: 0.0625rem;
  --vf-py-slider-tooltip-lg: 0.1875rem;
  --vf-px-slider-tooltip: 0.375rem;
  --vf-px-slider-tooltip-sm: 0.3125rem;
  --vf-px-slider-tooltip-lg: 0.5rem;
  --vf-py-blockquote: 0.25rem;
  --vf-py-blockquote-sm: 0.25rem;
  --vf-py-blockquote-lg: 0.25rem;
  --vf-px-blockquote: 0.75rem;
  --vf-px-blockquote-sm: 0.75rem;
  --vf-px-blockquote-lg: 0.75rem;
  --vf-py-hr: 0.25rem;
  --vf-space-addon: 0px;
  --vf-space-addon-sm: 0px;
  --vf-space-addon-lg: 0px;
  --vf-space-checkbox: 0.375rem;
  --vf-space-checkbox-sm: 0.375rem;
  --vf-space-checkbox-lg: 0.375rem;
  --vf-space-tags: 0.1875rem;
  --vf-space-tags-sm: 0.1875rem;
  --vf-space-tags-lg: 0.1875rem;
  --vf-space-static-tag-1: 1rem;
  --vf-space-static-tag-2: 2rem;
  --vf-space-static-tag-3: 3rem;
  --vf-floating-top: 0rem;
  --vf-floating-top-sm: 0rem;
  --vf-floating-top-lg: 0.6875rem;
  --vf-bg-input: #ffffff;
  --vf-bg-input-hover: #ffffff;
  --vf-bg-input-focus: #ffffff;
  --vf-bg-input-danger: #ffffff;
  --vf-bg-input-success: #ffffff;
  --vf-bg-checkbox: #ffffff;
  --vf-bg-checkbox-hover: #ffffff;
  --vf-bg-checkbox-focus: #ffffff;
  --vf-bg-checkbox-danger: #ffffff;
  --vf-bg-checkbox-success: #ffffff;
  --vf-bg-disabled: var(--vf-gray-200);
  --vf-bg-selected: #1118270d;
  --vf-bg-passive: var(--vf-gray-300);
  --vf-bg-icon: var(--vf-gray-500);
  --vf-bg-danger: var(--vf-danger-lighter);
  --vf-bg-success: var(--vf-success-lighter);
  --vf-bg-tag: var(--vf-primary);
  --vf-bg-slider-handle: var(--vf-primary);
  --vf-bg-toggle-handle: #ffffff;
  --vf-bg-date-head: var(--vf-gray-100);
  --vf-bg-addon: #ffffff00;
  --vf-bg-btn: var(--vf-primary);
  --vf-bg-btn-danger: var(--vf-danger);
  --vf-bg-btn-secondary: var(--vf-gray-200);
  --vf-bg-table-header: var(--vf-gray-100);
  --vf-color-input: var(--vf-gray-800);
  --vf-color-input-hover: var(--vf-gray-800);
  --vf-color-input-focus: var(--vf-gray-800);
  --vf-color-input-danger: var(--vf-gray-800);
  --vf-color-input-success: var(--vf-gray-800);
  --vf-color-disabled: var(--vf-gray-400);
  --vf-color-placeholder: var(--vf-gray-300);
  --vf-color-passive: var(--vf-gray-700);
  --vf-color-muted: var(--vf-gray-500);
  --vf-color-floating: var(--vf-gray-500);
  --vf-color-floating-focus: var(--vf-gray-500);
  --vf-color-floating-success: var(--vf-gray-500);
  --vf-color-floating-danger: var(--vf-gray-500);
  --vf-color-danger: var(--vf-danger);
  --vf-color-success: var(--vf-success);
  --vf-color-tag: var(--vf-color-on-primary);
  --vf-color-addon: var(--vf-gray-800);
  --vf-color-date-head: var(--vf-gray-700);
  --vf-color-btn: var(--vf-color-on-primary);
  --vf-color-btn-danger: #ffffff;
  --vf-color-btn-secondary: var(--vf-gray-700);
  --vf-color-table-header: var(--vf-gray-800);
  --vf-border-color-input: var(--vf-gray-300);
  --vf-border-color-input-hover: var(--vf-gray-300);
  --vf-border-color-input-focus: var(--vf-primary);
  --vf-border-color-input-danger: var(--vf-gray-300);
  --vf-border-color-input-success: var(--vf-gray-300);
  --vf-border-color-checkbox: var(--vf-gray-300);
  --vf-border-color-checkbox-focus: var(--vf-primary);
  --vf-border-color-checkbox-hover: var(--vf-gray-300);
  --vf-border-color-checkbox-danger: var(--vf-gray-300);
  --vf-border-color-checkbox-success: var(--vf-gray-300);
  --vf-border-color-checked: var(--vf-primary);
  --vf-border-color-passive: var(--vf-gray-300);
  --vf-border-color-slider-tooltip: var(--vf-primary);
  --vf-border-color-tag: var(--vf-primary);
  --vf-border-color-btn: var(--vf-primary);
  --vf-border-color-btn-danger: var(--vf-danger);
  --vf-border-color-btn-secondary: var(--vf-gray-200);
  --vf-border-color-blockquote: var(--vf-gray-300);
  --vf-border-color-hr: var(--vf-gray-300);
  --vf-border-color-signature-hr: var(--vf-gray-300);
  --vf-border-color-table: var(--vf-gray-300);
  --vf-border-width-input-t: 1px;
  --vf-border-width-input-r: 1px;
  --vf-border-width-input-b: 1px;
  --vf-border-width-input-l: 1px;
  --vf-border-width-radio-t: 1px;
  --vf-border-width-radio-r: 1px;
  --vf-border-width-radio-b: 1px;
  --vf-border-width-radio-l: 1px;
  --vf-border-width-checkbox-t: 1px;
  --vf-border-width-checkbox-r: 1px;
  --vf-border-width-checkbox-b: 1px;
  --vf-border-width-checkbox-l: 1px;
  --vf-border-width-dropdown: 1px;
  --vf-border-width-btn: 1px;
  --vf-border-width-toggle: 0.125rem;
  --vf-border-width-tag: 1px;
  --vf-border-width-blockquote: 3px;
  --vf-border-width-table: 1px;
  --vf-shadow-input: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-input-hover: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-input-focus: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-handles: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-handles-hover: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-handles-focus: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-btn: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-shadow-dropdown: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  --vf-radius-input: 0.25rem;
  --vf-radius-input-sm: 0.25rem;
  --vf-radius-input-lg: 0.25rem;
  --vf-radius-btn: 0.25rem;
  --vf-radius-btn-sm: 0.25rem;
  --vf-radius-btn-lg: 0.25rem;
  --vf-radius-small: 0.25rem;
  --vf-radius-small-sm: 0.25rem;
  --vf-radius-small-lg: 0.25rem;
  --vf-radius-large: 0.25rem;
  --vf-radius-large-sm: 0.25rem;
  --vf-radius-large-lg: 0.25rem;
  --vf-radius-tag: 0.25rem;
  --vf-radius-tag-sm: 0.25rem;
  --vf-radius-tag-lg: 0.25rem;
  --vf-radius-checkbox: 0.25rem;
  --vf-radius-checkbox-sm: 0.25rem;
  --vf-radius-checkbox-lg: 0.25rem;
  --vf-radius-slider: 0.25rem;
  --vf-radius-slider-sm: 0.25rem;
  --vf-radius-slider-lg: 0.25rem;
  --vf-radius-image: 0.25rem;
  --vf-radius-image-sm: 0.25rem;
  --vf-radius-image-lg: 0.25rem;
  --vf-radius-gallery: 0.25rem;
  --vf-radius-gallery-sm: 0.25rem;
  --vf-radius-gallery-lg: 0.25rem;
  --vf-checkbox-size: 1rem;
  --vf-checkbox-size-sm: 0.875rem;
  --vf-checkbox-size-lg: 1rem;
  --vf-gallery-size: 6rem;
  --vf-gallery-size-sm: 5rem;
  --vf-gallery-size-lg: 7rem;
  --vf-toggle-width: 3rem;
  --vf-toggle-width-sm: 2.75rem;
  --vf-toggle-width-lg: 3rem;
  --vf-toggle-height: 1.25rem;
  --vf-toggle-height-sm: 1rem;
  --vf-toggle-height-lg: 1.25rem;
  --vf-slider-height: 0.375rem;
  --vf-slider-height-sm: 0.3125rem;
  --vf-slider-height-lg: 0.5rem;
  --vf-slider-height-vertical: 20rem;
  --vf-slider-height-vertical-sm: 20rem;
  --vf-slider-height-vertical-lg: 20rem;
  --vf-slider-handle-size: 1rem;
  --vf-slider-handle-size-sm: 0.875rem;
  --vf-slider-handle-size-lg: 1.25rem;
  --vf-slider-tooltip-distance: 0.5rem;
  --vf-slider-tooltip-distance-sm: 0.375rem;
  --vf-slider-tooltip-distance-lg: 0.5rem;
  --vf-slider-tooltip-arrow-size: 0.3125rem;
  --vf-slider-tooltip-arrow-size-sm: 0.3125rem;
  --vf-slider-tooltip-arrow-size-lg: 0.3125rem;

  .group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 10px;
    background-color: #f0f0f0;
    border-radius: 5px;
    max-width: 100%;
  }

  .toggle-icon {
    cursor: pointer;

    margin-left: -50px;
    transition: transform 0.3s ease;
  }

  .toggle-icon.is-open {
    transform: rotate(180deg);
  }

  .fade-enter-active,
  .fade-leave-active {
    transition: opacity 0.5s;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .border-group {
    border: 1px solid #e5e5e5 !important;
    padding: 1rem !important;
  }

  .toggle-icon-container {
    cursor: pointer;
    margin-left: -65px;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-tongtrang {
    margin-left: -160px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-tiensubenh {
    margin-left: -160px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-tiensubenhxahoi {
    margin-left: -90px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-tinhthan {
    margin-left: -180px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-vandong {
    margin-left: -180px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-dinhduong {
    margin-left: -180px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-duonghuyet {
    margin-left: -50px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-chamsocbanchan {
    margin-left: -50px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-sudungthuoc {
    margin-left: -50px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-hoctap {
    margin-left: -50px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }
  .toggle-icon-container-muctieu {
    margin-left: -50px;
    cursor: pointer;
    transform: scale(0.8);
    margin-top: -3px;
  }

  .labeltext {
    font-weight: 600;
    color: #000;
  }
}
</style>
