var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Vueform',{ref:"vueform",attrs:{"name":"mainvueform","display-errors":true,"validate-on":"step|change","size":"sm","display-messages":true,"add-class":"vf-coach-comment"},on:{"before-next-step":_vm.onBeforeNextStep},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('FormSteps',{ref:"formSteps"},[_c('FormStep',{attrs:{"name":"page0","elements":[
            'containerThongTin',
            'containerThongTinHanhChinh',
            'divider_thongtinhanhchinh',
            'containerTongTrang',
            'divider_tongtrang',
            'containerTienSuBenh',
            'divider_tiensubenh',
            'containerTienSuBenhXaHoi',
          ],"label":"THÔNG TIN","labels":{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}}}),_c('FormStep',{attrs:{"name":"page1","elements":[
            'containerThongTin',
            'containerTinhThan',
            'divider_tinhthan',
            'containerVanDong',
            'divider_vandong',
            'containerDinhDuong',
          ],"label":"HOẠT ĐỘNG & DINH DƯỠNG","labels":{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}}}),_c('FormStep',{attrs:{"name":"page2","elements":[
            'containerThongTin',
            'containerDuongHuyet',
            'divide-chamsocbanchan',
            'containerBanChan',
            'divide-SuDungThuoc',
            'containerSuDungThuoc',
            'divide-HocTap',
            'containerHocTap',
          ],"label":"KHẢ NĂNG TỰ CHĂM SÓC","labels":{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}}}),_c('FormStep',{attrs:{"name":"page3","label":"ĐỘNG LỰC & MỤC TIÊU","elements":[
            'containerThongTin',
            'containerDLVMT'
          ],"labels":{
  next: 'Tiếp theo',
  previous: 'Quay lại',
  finish: 'Hoàn thành'
}}})],1),_c('FormElements',{attrs:{"name":"vueelement"}},[_c('GroupElement',{attrs:{"name":"containerThongTin"}},[_c('ButtonElement',{attrs:{"name":"button","loading":_vm.loading},on:{"click":_vm.createPageOne}},[_vm._v(" Submit ")]),_c('StaticElement',{attrs:{"name":"h1","tag":"h3","content":"PHIẾU KẾ HOẠCH GIÁO DỤC VÀ HỖ TRỢ TỰ QUẢN LÝ BỆNH ĐTĐ (DSMES PLAN FORM)\n","align":"center"}}),_c('TextElement',{staticClass:"labeltext",attrs:{"name":"courseName","label":"Khóa","columns":{
              container: 4,
            }}}),_c('DateElement',{staticClass:"labeltext",attrs:{"name":"executionDate","label":"Ngày thực hiện","columns":{
              container: 4,
            }}}),_c('TextElement',{staticClass:"labeltext",attrs:{"name":"executorName","label":"Người thực hiện (Họ và tên)","columns":{
              container: 4,
            }}}),_c('ButtonElement',{attrs:{"name":"buttonExpand","loading":_vm.loading,"columns":{
              container: 4,
            }},on:{"click":_vm.expandAll}},[_vm._v(" Expand All ")]),_c('ButtonElement',{attrs:{"name":"buttonCollapse","loading":_vm.loading,"columns":{
              container: 4,
            }},on:{"click":_vm.collapseAll}},[_vm._v(" Collapse All ")]),_c('StaticElement',{attrs:{"name":"divider_thongtinchung","tag":"hr"}})],1),_c('GroupElement',{attrs:{"name":"containerThongTinHanhChinh"}},[_c('StaticElement',{attrs:{"name":"header_thong_tin_hanh_chinh","tag":"h4","content":"THÔNG TIN HÀNH CHÍNH","columns":{ container: 3 }}}),_c('div',{staticClass:"toggle-icon-container"},[_c('span',{on:{"click":_vm.toggleThongTinHanhChinh}},[_c('inline-svg',{attrs:{"src":_vm.isShowThongTinHanhChinh
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowThongTinHanhChinh)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_16"}},[_c('TextElement',{attrs:{"name":"fullName","label":"Họ và tên","columns":{
                container: 7,
              }}}),_c('TextElement',{attrs:{"name":"yearOfBirth","label":"Năm sinh","input-type":"number","columns":{
                container: 3,
              }}}),_c('SelectElement',{attrs:{"id":"gender","name":"gender","items":_vm.genders,"search":true,"native":false,"label":"Giới tính","input-type":"search","autocomplete":"off","columns":{
                container: 2,
              }}}),_c('TextElement',{attrs:{"name":"phoneNumber","label":"Số điện thoại","columns":{
                container: 6,
              }}}),_c('TextElement',{attrs:{"name":"residence","label":"Nơi sống","columns":{
                container: 3,
              }}}),_c('TextElement',{attrs:{"name":"job","label":"Nghề nghiệp","columns":{
                container: 3,
              }}}),_c('SelectElement',{attrs:{"id":"diseaseType","name":"diseaseType","items":_vm.diseasesOpts,"search":true,"native":false,"label":"Loại Đái tháo đường","input-type":"search","autocomplete":"off","columns":{
                container: 6,
              }}}),_c('TextElement',{attrs:{"name":"diagnosedYear","label":"Chẩn đoán từ năm","columns":{
                container: 5,
              }}}),_c('StaticElement',{attrs:{"name":"p_9","tag":"h4","content":"KẾT QUẢ KHẢ NĂNG TỰ CHĂM SÓC","columns":{
                container: 6,
              }}}),_c('StaticElement',{attrs:{"name":"p_10","tag":"h4","columns":{
                container: 6,
              },"content":"KẾT QUẢ KHẢO SÁT KIẾN THỨC"}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container4_1"}},[_c('GroupElement',{attrs:{"name":"column2_4","columns":{
                  container: 6,
                },"label":"Mức độ kiến thức"}},[_c('TextElement',{attrs:{"name":"knowledgePoint","columns":{
                    container: 8,
                  }}}),_c('TextElement',{attrs:{"name":"knowledgeAverage","label":"Điểm  trung bình (/10)","columns":{
                    container: 8,
                  }}})],1),_c('GroupElement',{attrs:{"name":"column4_1","columns":{
                  container: 6,
                }}},[_c('TextElement',{attrs:{"name":"selfCarePoint","label":"Mức độ Tự Chăm sóc","columns":{
                    container: 8,
                  }}}),_c('TextElement',{attrs:{"name":"selfCareAverage","label":"Điểm  trung bình (/10)","columns":{
                    container: 8,
                  }}})],1)],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divider_thongtinhanhchinh","tag":"hr"}}),_c('GroupElement',{attrs:{"columns":{
            container: 9,
          },"name":"containerTongTrang"}},[_c('StaticElement',{attrs:{"name":"title_Tongtrang","tag":"h4","content":"TỔNG TRẠNG","columns":{ container: 4 }}}),_c('div',{staticClass:"toggle-icon-container-tongtrang",on:{"click":_vm.toggleTongTrang}},[_c('inline-svg',{attrs:{"src":_vm.isShowTongTrang
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowTongTrang)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"tongtrang"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column1","columns":{
                container: 9,
              }}},[_c('TextElement',{attrs:{"name":"weight","label":"Cân nặng (kg)","columns":{
                  container: 4,
                }}}),_c('TextElement',{attrs:{"name":"height","label":"Chiều cao (cm)","columns":{
                  container: 4,
                }}}),_c('TextElement',{attrs:{"name":"bmi","label":"BMI (1 kg/m2)","columns":{
                  container: 4,
                }}}),_c('TextElement',{attrs:{"name":"statusTongTrang","label":"Tổng trạng"}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"issueTongTrangGroup","columns":{
                container: 3,
              }}},[_c('StaticElement',{attrs:{"name":"title_issueTongTrang","tag":"h4","content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"issueTongTrang"}})],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divider_tongtrang","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerTinhThan"}},[_c('StaticElement',{attrs:{"name":"h4_19","tag":"h4","content":"TINH THẦN","columns":{
              container: 3,
            }}}),_c('div',{staticClass:"toggle-icon-container-tinhthan",on:{"click":_vm.toggleTinhThan}},[_c('inline-svg',{attrs:{"src":_vm.isShowTinhThan
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowTinhThan)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_6"}},[_c('GroupElement',{attrs:{"name":"column1","columns":{
                  container: 9,
                }}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_7"}},[_c('GroupElement',{attrs:{"name":"column1","columns":{
                      container: 7,
                    }}},[_c('StaticElement',{attrs:{"name":"title_sleep_problem","tag":"p","content":"Bạn có gặp vấn đề gì về giấc ngủ không (mất ngủ, ngủ không ngon giấc...)?"}})],1),_c('GroupElement',{attrs:{"name":"column2","columns":{
                      container: 5,
                    }}},[_c('RadiogroupElement',{attrs:{"name":"sleepingProblem","view":"tabs","items":[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ]}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_8"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_9"}},[_c('GroupElement',{attrs:{"name":"column1","columns":{
                        container: 2,
                      }}},[_c('StaticElement',{attrs:{"name":"title_sleepingProblemExpand","tag":"p","content":"Vấn đề giấc ngủ","columns":{
                          container: 10,
                        }}})],1),_c('GroupElement',{attrs:{"name":"column2","columns":{
                        container: 10,
                      }}},[_c('GroupElement',{attrs:{"name":"column2_6"}},[_c('CheckboxElement',{attrs:{"name":"insomnia","columns":{
                            container: 4,
                          },"text":"Mất ngủ"}}),_c('CheckboxElement',{attrs:{"name":"poorSleepQuality","text":"Ngủ không ngon ","columns":{
                            container: 4,
                          }}}),_c('CheckboxElement',{attrs:{"name":"difficultyFallingAsleep","text":"Khó bắt đầu giấc ngủ","columns":{
                            container: 4,
                          }}}),_c('CheckboxElement',{attrs:{"name":"frequentWaking","text":"Hay bị thức giấc ","columns":{
                            container: 4,
                          }}}),_c('CheckboxElement',{attrs:{"name":"useSleepingPills","text":"Dùng thuốc ngủ ","columns":{
                            container: 4,
                          }}})],1),_c('GroupElement',{attrs:{"name":"column2_8","columns":{
                          container: 12,
                        }}},[_c('TextElement',{attrs:{"name":"nightSleepingTime","label":"Giờ ngủ tối(h)","columns":{
                            default: {
                              container: 6,
                            },
                          }}}),_c('TextElement',{attrs:{"name":"nightMiddaySleepTime","label":"Giờ ngủ trưa(h)","columns":{
                            default: {
                              container: 6,
                            },
                          }}}),_c('TextElement',{attrs:{"name":"totalSleepTime","label":"Tổng thời gian ngủ trong ngày(h)","columns":{
                            default: {
                              container: 12,
                            },
                          }}})],1)],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_10"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column2_9","columns":{
                        container: 12,
                      }}},[_c('GroupElement',{attrs:{"name":"column2_10"}},[_c('StaticElement',{attrs:{"name":"title_anxiety","tag":"p","content":"Lo lắng","columns":{
                            container: 3,
                          }}}),_c('RadiogroupElement',{attrs:{"name":"problemWorry","view":"tabs","items":[
                            {
                              value: false,
                              label: 'Không',
                            },
                            {
                              value: true,
                              label: 'Có',
                            },
                          ],"columns":{
                            container: 3,
                          }}}),_c('TextElement',{attrs:{"disabled":_vm.problemWorry,"name":"problemWorryInput","placeholder":"Vấn đề lo lắng","columns":{
                            default: {
                              container: 4,
                            },
                          }}})],1),_c('GroupElement',{attrs:{"name":"column2_12","columns":{
                          container: 12,
                        }}},[_c('StaticElement',{attrs:{"name":"p_12","tag":"p","content":"Căng thẳng","columns":{
                            container: 3,
                          }}}),_c('RadiogroupElement',{attrs:{"name":"problemStress","view":"tabs","items":[
                            {
                              value: false,
                              label: 'Không',
                            },
                            {
                              value: true,
                              label: 'Có',
                            },
                          ],"columns":{
                            container: 3,
                          }}}),_c('TextElement',{attrs:{"disabled":_vm.problemStress,"name":"problemStressInput","placeholder":"Vấn đề căng thẳng","columns":{
                            default: {
                              container: 4,
                            },
                          }}})],1)],1),_c('GroupElement',{attrs:{"name":"column2_11","columns":{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column2_13","columns":{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }}},[_c('StaticElement',{attrs:{"name":"p_13","tag":"p","content":"Các biện pháp đang sử dụng để giảm căng thẳng, giảm lo lắng, rối loạn giấc ngủ (Nếu có)"}}),_c('GroupElement',{attrs:{"name":"column2_14","columns":{
                          default: {
                            container: 10,
                          },
                          lg: {
                            container: 12,
                          },
                        }}},[_c('CheckboxElement',{attrs:{"name":"optRelax","columns":{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          },"text":"Thư giãn"}}),_c('CheckboxElement',{attrs:{"name":"optMeditation","text":"Thiền","columns":{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          }}}),_c('CheckboxElement',{attrs:{"name":"optBreath","text":"Hít thở","columns":{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 2,
                            },
                          }}}),_c('TextElement',{staticStyle:{"margin-top":"-15px"},attrs:{"name":"otherReduceStressInput","label":"Khác","columns":{
                            default: {
                              container: 4,
                            },
                            lg: {
                              container: 4,
                            },
                          }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column2_16","columns":{
                        default: {
                          container: 12,
                        },
                      }}},[_c('StaticElement',{staticStyle:{"font-weight":"bold"},attrs:{"name":"p_14","tag":"p","content":"Tôn giáo, tín ngưỡng","columns":{
                          default: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"optTrustNo","columns":{
                          default: {
                            container: 3,
                          },
                        },"text":"Không"}}),_c('CheckboxElement',{attrs:{"name":"optTrustBuddha","text":"Phật giáo","columns":{
                          default: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"optTrusInChrist","text":"Thiên chúa giáo","columns":{
                          default: {
                            container: 3,
                          },
                        }}}),_c('StaticElement',{attrs:{"name":"title_space","columns":{
                          default: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"optTrustIslam","text":"Hồi giáo","columns":{
                          default: {
                            container: 2,
                          },
                        }}}),_c('TextElement',{staticStyle:{"margin-top":"-15px"},attrs:{"name":"otherTrustInput","label":"Khác","columns":{
                          default: {
                            container: 4,
                          },
                        }}})],1),_c('GroupElement',{attrs:{"name":"column2_17","columns":{
                        default: {
                          container: 10,
                        },
                        lg: {
                          container: 12,
                        },
                      }}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column1_10","columns":{
                          container: 12,
                        }}},[_c('StaticElement',{attrs:{"name":"p_15","tag":"p","content":"Khác","columns":{
                            container: 2,
                          }}}),_c('TextElement',{attrs:{"name":"text_11","columns":{
                            default: {
                              container: 10,
                            },
                          }}})],1)],1)],1)],1)],1),_c('GroupElement',{attrs:{"name":"column2","columns":{
                  container: 3,
                }}},[_c('StaticElement',{attrs:{"name":"h4_21","tag":"h4","columns":{
                    container: 12,
                  },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"issueTinhThan"}})],1)],1):_vm._e()],1)],1),_c('StaticElement',{attrs:{"name":"divider_tinhthan","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerVanDong"}},[_c('StaticElement',{attrs:{"name":"h4_22","tag":"h4","content":"VẬN ĐỘNG","columns":{
              container: 3,
            }}}),_c('div',{staticClass:"toggle-icon-container-vandong",on:{"click":_vm.toggleVanDong}},[_c('inline-svg',{attrs:{"src":_vm.isShowVanDong
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowVanDong)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_12 "}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column1_8","columns":{
                  container: 9,
                }}},[_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","label":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn tham gia một môn thể dục thể thao cụ thể (như là bơi lội, đi bộ, đạp xe…) không bao gồm việc nhà hoặc công việc thường ngày? (ngày)","name":"activityOneWeek","columns":{
                    lg: {
                      container: 12,
                    },
                  }}}),_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","name":"activityOneWeek30min","label":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn vận động thể chất ít nhất 30 phút (tổng số phút của các hoạt động liên tục, bao gồm đi bộ)? (ngày)","columns":{
                    default: {
                      container: 12,
                    },
                  }}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_15"}},[_c('StaticElement',{attrs:{"name":"p_17","tag":"p","content":"Vận động thể chất","columns":{
                      container: 3,
                    }}}),_c('RadiogroupElement',{attrs:{"name":"activityWorkout","view":"tabs","items":[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có (Nếu có hỏi tiếp những câu hỏi bên dưới)',
                      },
                    ],"columns":{
                      container: 9,
                    }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_16"}},[_c('GroupElement',{attrs:{"name":"column1_13","columns":{
                      default: {
                        container: 2,
                      },
                      lg: {
                        container: 3,
                      },
                    }}},[_c('StaticElement',{attrs:{"name":"physicalActivityType","tag":"p","content":"Loại hình vận động","columns":{
                        container: 10,
                      }}})],1),_c('GroupElement',{attrs:{"name":"column2_23","columns":{
                      default: {
                        container: 10,
                      },
                      lg: {
                        container: 9,
                      },
                    }}},[_c('GroupElement',{attrs:{"name":"column2_24"}},[_c('CheckboxElement',{attrs:{"name":"yoga","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        },"text":"Yoga"}}),_c('CheckboxElement',{attrs:{"name":"swimming","text":"Bơi lội","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"walking","text":"Đi bộ","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"qigong","text":"Dưỡng sinh ","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"gym","text":"Gym ","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"cycling","text":"Đạp xe","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"running","text":"Chạy bộ","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}}),_c('CheckboxElement',{attrs:{"name":"otherPhysicalActivity","text":"Khác ","columns":{
                          default: {
                            container: 4,
                          },
                          lg: {
                            container: 3,
                          },
                        }}})],1)],1),_c('GroupElement',{attrs:{"name":"column2_25","columns":{
                      default: {
                        container: 10,
                      },
                      lg: {
                        container: 12,
                      },
                    }}},[_c('GroupElement',{attrs:{"name":"column1_14"}},[_c('StaticElement',{attrs:{"name":"p_19","tag":"p","content":"Ngồi xem tivi/làm việc ","columns":{
                          container: 3,
                        }}}),_c('TextElement',{attrs:{"placeholder":"giờ/ngày","name":"text_37","columns":{
                          default: {
                            container: 8,
                          },
                        }}}),_c('StaticElement',{attrs:{"name":"p_22","tag":"p","content":"Khác","columns":{
                          container: 2,
                        }}}),_c('TextElement',{attrs:{"name":"text_40","columns":{
                          default: {
                            container: 9,
                          },
                        }}})],1)],1)],1)],1),_c('GroupElement',{attrs:{"name":"container2_17","columns":{
                  default: {
                    container: 3,
                  },
                }}},[_c('StaticElement',{attrs:{"name":"h4_23","tag":"h4","columns":{
                    container: 12,
                  },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_4","columns":{
                    default: {
                      container: 12,
                    },
                  }}})],1)],1):_vm._e()],1)],1),_c('StaticElement'),_c('StaticElement',{attrs:{"name":"divider_vandong","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerTienSuBenh"}},[_c('StaticElement',{attrs:{"name":"title_tiensubenh","tag":"h4","content":"TIỀN SỬ BỆNH","columns":{
              container: 3,
            }}}),_c('div',{staticClass:"toggle-icon-container-tiensubenh",on:{"click":_vm.toggleTienSuBenh}},[_c('inline-svg',{attrs:{"src":_vm.isShowTienSuBenh
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowTienSuBenh)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"medicalHistoryGroup"}},[_c('GroupElement',{attrs:{"name":"column1","columns":{
                container: 9,
              }}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerMEDICAL"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"medicalHistoryQuestion","content":"Bạn có các bệnh dưới đây không? ( KQKS )","columns":{
                    container: 5,
                  }}}),_c('TextElement',{attrs:{"name":"medicalHistoryInput","columns":{
                    container: 7,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_1"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_hypoglycemiaQuestion","input-type":"number","content":"Hạ đường huyết","columns":{
                    container: 3,
                  }}}),_c('RadiogroupElement',{attrs:{"id":"hypoglycemia","name":"hypoglycemia","view":"tabs","items":[
                    {
                      value: 0,
                      label: 'Chưa bao giờ',
                    },
                    {
                      value: 1,
                      label: 'Thỉnh thoảng',
                    },
                    {
                      value: 2,
                      label: 'Thường xuyên',
                    },
                  ]}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_3"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_hypoglycemiaLevelQuestion","content":"Đường huyết lúc hạ đường huyết","columns":{
                    container: 6,
                  }}}),_c('TextElement',{attrs:{"name":"hypoglycemiaNumber","input-type":"number","label":"Chỉ số","columns":{
                    container: 3,
                  }}}),_c('SelectElement',{attrs:{"id":"hypoglycemiaUnit","name":"hypoglycemiaUnit","items":[
                    {
                      value: 0,
                      label: 'mg/dl',
                    },
                    {
                      value: 1,
                      label: 'mmol/L',
                    },
                  ],"search":true,"native":false,"label":"Đơn vị","input-type":"search","autocomplete":"off","columns":{
                    container: 3,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_99"}},[_c('GroupElement',{attrs:{"columns":{
                    container: 3,
                  }}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_symptom","content":"Biểu hiện","columns":{
                      container: 4,
                    }}})],1),_c('GroupElement',{attrs:{"columns":{
                    container: 8,
                  }}},[_c('CheckboxElement',{attrs:{"name":"sweating","text":"Đổ mồ hôi","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"shaking","text":"Run tay","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"anxiety","text":"Lo lắng","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"dizziness","text":"Chóng mặt","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"hunger","text":"Đói","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"rapidHeartbeat","text":"Tim đập nhanh","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"blurredVision","text":"Nhìn mờ","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"fatigue","text":"Mệt mỏi","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"headache","text":"Đau đầu","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}}),_c('CheckboxElement',{attrs:{"name":"irritability","text":"Cáu gắt","true-value":1,"false-value":0,"columns":{
                      container: 3,
                    }}})],1)],1),_c('GroupElement',{staticClass:"border-group"},[_c('EditorElement',{staticClass:"labeltext",attrs:{"id":"treatmentMethod","label":"Cách xử trí","name":"treatmentMethod","columns":{
                    container: 12,
                  }}}),_c('TextElement',{staticClass:"labeltext",attrs:{"label":"Khác","name":"otherQuestionTienSuBenh","columns":{
                    container: 12,
                  }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"columns":{
                container: 3,
              }}},[_c('StaticElement',{attrs:{"name":"medicalHistoryQuestion","tag":"h4","content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"issueHistoryMedical"}})],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divider_tiensubenh","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerTienSuBenhXaHoi"}},[_c('StaticElement',{attrs:{"name":"title_TienSuBenhXH","tag":"h4","content":"TIỀN SỬ BỆNH XÃ HỘI","columns":{
              container: 3,
            }}}),_c('div',{staticClass:"toggle-icon-container-tiensubenhxahoi",on:{"click":_vm.toggleTienSuBenhXaHoi}},[_c('inline-svg',{attrs:{"src":_vm.isShowTienSuBenhXaHoi
                  ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                  : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)],1),(_vm.isShowTienSuBenhXaHoi)?_c('GroupElement',{staticClass:"border-group"},[_c('GroupElement',[_c('GroupElement',{attrs:{"name":"column1_2","columns":{
                container: 9,
              }}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_8"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"labeltext_alcohol","content":"Bạn có sử dụng rượu/bia không? ( KQKS )","columns":{
                    container: 5,
                  }}}),_c('TextElement',{attrs:{"name":"alcoholUse","columns":{
                    container: 7,
                  },"placeholder":" (KQKS)"}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_9"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_frequency","content":"Tần suất:","columns":{
                    container: 2,
                  }}}),_c('TextElement',{attrs:{"name":"frequency","columns":{
                    container: 5,
                  },"placeholder":" (KQKS)"}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_14"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_amount","content":"Số lượng:","columns":{
                    container: 2,
                  }}}),_c('TextElement',{attrs:{"input-type":"number","name":"amount","columns":{
                    container: 5,
                  },"placeholder":" (KQKS)"}}),_c('StaticElement',{attrs:{"name":"title_unit","content":" (ml/lần) ","columns":{
                    container: 2,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_11"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_smoking","content":"Hiện tại bạn có hút thuốc lá không?","columns":{
                    container: 6,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"smokingStatus","view":"tabs","items":[
                    {
                      value: 1,
                      label: 'Không',
                    },
                    {
                      value: 0,
                      label: 'Có',
                    },
                  ]}}),_c('StaticElement',{attrs:{"name":"title_unit","content":" ","columns":{
                    container: 1,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_12"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_average_smoking","content":"Trung bình Bạn hút bao nhiêu điếu một ngày ","columns":{
                    container: 7,
                  }}}),_c('TextElement',{staticStyle:{"margin-top":"-10px"},attrs:{"name":"averageCigarettesPerDay","columns":{
                    container: 2,
                  }}}),_c('StaticElement',{attrs:{"name":"title_unit_inday","content":"điếu/ngày","columns":{
                    container: 1,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_10"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_mostly_smoking","content":"Khi nào Bạn thấy mình sử dụng thuốc lá nhiều nhất trong ngày?","columns":{
                    container: 7,
                  }}}),_c('TextElement',{staticStyle:{"margin-top":"-10px"},attrs:{"name":"whenMostlySmoke","columns":{
                    container: 2,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_13"}},[_c('CheckboxElement',{staticClass:"labeltext",attrs:{"name":"livingAlone","text":" Sống một mình","columns":{
                    container: 4,
                  }}}),_c('TextElement',{staticClass:"labeltext",staticStyle:{"margin-top":"-10px"},attrs:{"name":"livingWithSomeone","label":"Sống chung với:","columns":{
                    container: 6,
                  }}}),_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"title_ismainpillar","content":"Trụ cột chính trong gia đình? ","columns":{
                    container: 4,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"mainPillar","view":"tabs","items":[
                    {
                      value: 1,
                      label: 'Không',
                    },
                    {
                      value: 0,
                      label: 'Có',
                    },
                  ]}}),_c('TextElement',{staticClass:"labeltext",attrs:{"label":"Khác","name":"otherBenhXh","columns":{
                    container: 12,
                  }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"columns":{
                container: 3,
              }}},[_c('StaticElement',{attrs:{"name":"HistoryMedical","tag":"h4","content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"issueHistoryMedicalXh"}})],1)],1)],1):_vm._e(),_c('GroupElement',{attrs:{"name":"containerDuongHuyet"}},[_c('StaticElement',{attrs:{"name":"titleDuongHuyet","tag":"h4","content":"THEO DÕI ĐƯỜNG HUYẾT","columns":{ container: 3 }}}),_c('div',{staticClass:"toggle-icon-container-duonghuyet"},[_c('span',{on:{"click":_vm.toggleDuongHuyet}},[_c('inline-svg',{attrs:{"src":_vm.isShowDuongHuyet
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowDuongHuyet)?_c('GroupElement',{attrs:{"name":"DuongHuyetExpand"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_19"}},[_c('GroupElement',{attrs:{"name":"column1_18","columns":{
                  container: 9,
                }}},[_c('GroupElement',{attrs:{"name":"container_4"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_20"}},[_c('StaticElement',{attrs:{"name":"h4_26","columns":{
                        container: 7,
                      },"tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn đo đường huyết?\n"}}),_c('TextElement',{attrs:{"placeholder":"ngày","name":"text_12","columns":{
                        container: 5,
                      }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_17"}},[_c('StaticElement',{attrs:{"name":"h4_28","tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn đo đường huyết theo số lần bác sĩ của bạn khuyến nghị?","columns":{
                      container: 7,
                    }}}),_c('TextElement',{attrs:{"placeholder":"ngày","name":"text_13","columns":{
                      container: 5,
                    }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_18"}},[_c('TextElement',{attrs:{"name":"text_70","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    },"label":"HbA1c (%)"}}),_c('DateElement',{attrs:{"name":"date_1","label":"Ngày xét nghiệm","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    },"display-format":"DD-MM-YYYY"}}),_c('TextElement',{attrs:{"name":"text_35","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    },"label":"Đường huyết đói "}}),_c('DateElement',{attrs:{"name":"date_2","label":"Ngày đo","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    },"display-format":"DD-MM-YYYY"}}),_c('TextElement',{attrs:{"name":"text_36","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 4,
                      },
                    },"label":"Đường huyết 2 giờ sau ăn"}}),_c('DateElement',{attrs:{"name":"date_3","label":"Ngày đo","columns":{
                      default: {
                        container: 8,
                      },
                      lg: {
                        container: 6,
                      },
                    },"display-format":"DD-MM-YYYY"}}),_c('TextElement',{attrs:{"name":"text_52","label":"Khác"}})],1)],1),_c('GroupElement',{attrs:{"name":"column2_22","columns":{
                  container: 3,
                }}},[_c('StaticElement',{attrs:{"name":"h4_25","tag":"h4","columns":{
                    container: 12,
                  },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_5"}})],1)],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divide-chamsocbanchan","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerBanChan"}},[_c('StaticElement',{attrs:{"name":"titlecsBanChan","tag":"h4","content":"CHĂM SÓC BÀN CHÂN ","columns":{ container: 3 }}}),_c('div',{staticClass:"toggle-icon-container-chamsocbanchan"},[_c('span',{on:{"click":_vm.togglechamSocBanChan}},[_c('inline-svg',{attrs:{"src":_vm.isShowBanChan
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowBanChan)?_c('GroupElement',{attrs:{"name":"BanChanExpand"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_19"}},[_c('GroupElement',{attrs:{"name":"column1_18","columns":{
                  container: 9,
                }}},[_c('GroupElement',{attrs:{"name":"container_4"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_20"}},[_c('StaticElement',{attrs:{"name":"h4_26","columns":{
                        container: 7,
                      },"tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn kiểm tra bàn chân của mình? (Kiểm tra lòng bàn chân, cảm giác của bàn chân, khe bàn chân,…)"}}),_c('TextElement',{attrs:{"placeholder":"ngày","name":"text_12","columns":{
                        container: 5,
                      }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_17"}},[_c('StaticElement',{attrs:{"name":"h4_28","tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn kiểm tra bên trong giày của mình? (Có vật lạ,…)","columns":{
                      container: 7,
                    }}}),_c('TextElement',{attrs:{"placeholder":"ngày","name":"text_13","columns":{
                      container: 5,
                    }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_23"}},[_c('StaticElement',{attrs:{"name":"title_ismainpillar","content":"Khám biến chứng bàn chân Đái tháo đường","columns":{
                      container: 4,
                    }}}),_c('RadiogroupElement',{attrs:{"name":"mainPillar","view":"tabs","items":[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ],"columns":{
                      container: 8,
                    }}}),_c('TextElement',{attrs:{"name":"text_69","label":"Khác"}})],1)],1),_c('GroupElement',{attrs:{"name":"column2_22","columns":{
                  container: 3,
                }}},[_c('StaticElement',{attrs:{"name":"h4_25","tag":"h4","columns":{
                    container: 12,
                  },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_5"}})],1)],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divide-SuDungThuoc","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerSuDungThuoc"}},[_c('StaticElement',{attrs:{"name":"titleSuDungThuoc","tag":"h4","content":"SỬ DỤNG THUỐC","columns":{ container: 3 }}}),_c('div',{staticClass:"toggle-icon-container-sudungthuoc"},[_c('span',{on:{"click":_vm.toggleSuDungThuoc}},[_c('inline-svg',{attrs:{"src":_vm.isShowSuDungThuoc
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isShowSuDungThuoc)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"ExpandSuDungThuoc"}},[_c('GroupElement',{attrs:{"name":"column1","columns":{
                default: {
                  container: 9,
                },
                lg: {
                  container: 9,
                },
              }}},[_c('GroupElement',{attrs:{"name":"container_20"}},[_c('GroupElement',{attrs:{"name":"container2_24","columns":{
                    lg: {
                      container: 12,
                    },
                  }}},[_c('StaticElement',{attrs:{"name":"h4_36","tag":"p","content":"Thuốc tiểu đường đang sử dụng","columns":{
                      default: {
                        container: 4,
                      },
                    }}}),_c('RadiogroupElement',{attrs:{"name":"problemFood","view":"tabs","items":[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ],"columns":{
                      container: 6,
                    }}})],1),_c('GroupElement',{attrs:{"name":"container2_37","columns":{
                    lg: {
                      container: 12,
                    },
                  }}},[_c('StaticElement',{attrs:{"name":"h4_33","tag":"p","content":"Chi tiết thuốc","columns":{
                      default: {
                        container: 4,
                      },
                    }}}),_c('MultifileElement',{attrs:{"name":"multiImage","label":"Thêm hình ảnh","view":"image","accept":"image/*","file":{
                      rules: [
                        'mimetypes:image/jpeg,image/png,image/gif,image/webp,image/svg+xml,image/tiff',
                      ],
                    },"columns":{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 3,
                      },
                    }}}),_c('MultifileElement',{attrs:{"name":"multifile","label":"Thêm tài liệu","columns":{
                      lg: {
                        container: 3,
                      },
                      default: {
                        container: 6,
                      },
                    },"urls":{}}})],1),_c('GroupElement',{attrs:{"name":"container2_38","columns":{
                    lg: {
                      container: 12,
                    },
                  }}},[_c('StaticElement',{attrs:{"name":"h4_34","tag":"p","content":"Thuốc tiểu đường đang sử dụng","columns":{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 4,
                      },
                    }}}),_c('GroupElement',{attrs:{"name":"container_19","columns":{
                      default: {
                        container: 9,
                      },
                      lg: {
                        container: 8,
                      },
                    }}},[_c('CheckboxElement',{attrs:{"name":"checkbox_77","columns":{
                        default: {
                          container: 5,
                        },
                        lg: {
                          container: 6,
                        },
                      },"text":"Không muốn dùng thuốc"}}),_c('CheckboxElement',{attrs:{"name":"checkbox_81","columns":{
                        default: {
                          container: 7,
                        },
                        lg: {
                          container: 6,
                        },
                      },"text":"Quên thường xuyên"}}),_c('CheckboxElement',{attrs:{"name":"checkbox_83","columns":{
                        default: {
                          container: 5,
                        },
                        lg: {
                          container: 6,
                        },
                      },"text":"Thỉnh thoảng quên  "}}),_c('CheckboxElement',{attrs:{"name":"checkbox_82","columns":{
                        default: {
                          container: 6,
                        },
                        lg: {
                          container: 6,
                        },
                      },"text":"Tuân thủ tốt"}})],1),_c('TextElement',{attrs:{"name":"text_71","label":"Khó khăn khi dùng thuốc "}})],1)],1)],1),_c('GroupElement',{attrs:{"name":"column2","columns":{
                default: {
                  container: 3,
                },
                lg: {
                  container: 3,
                },
              }}},[_c('StaticElement',{attrs:{"name":"h4_31","tag":"h4","columns":{
                  container: 12,
                },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_7"}})],1)],1):_vm._e()],1),_c('StaticElement',{attrs:{"name":"divide-HocTap","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerHocTap"}},[_c('StaticElement',{attrs:{"name":"titleSuDungThuoc","tag":"h4","content":"KHẢ NĂNG HỌC TẬP","columns":{ container: 3 }}}),_c('div',{staticClass:"toggle-icon-container-hoctap"},[_c('span',{on:{"click":_vm.toggleHocTap}},[_c('inline-svg',{attrs:{"src":_vm.isShowHocTap
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),(_vm.isShowHocTap)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerHocTapExpand"}},[_c('GroupElement',{attrs:{"name":"column1_23","columns":{
              default: {
                container: 9,
              },
              lg: {
                container: 9,
              },
            }}},[_c('GroupElement',{attrs:{"name":"container_26"}},[_c('GroupElement',{attrs:{"name":"container2_26","columns":{
                  lg: {
                    container: 12,
                  },
                }}},[_c('StaticElement',{attrs:{"name":"h4_37","tag":"p","content":"Bạn học tốt nhất thông qua:","columns":{
                    default: {
                      container: 3,
                    },
                    lg: {
                      container: 4,
                    },
                  }}}),_c('CheckboxElement',{attrs:{"name":"checkbox_48","columns":{
                    default: {
                      container: 1,
                    },
                    lg: {
                      container: 2,
                    },
                  },"text":"Nghe "}}),_c('CheckboxElement',{attrs:{"name":"checkbox_49","text":"Nhìn","columns":{
                    default: {
                      container: 1,
                    },
                    lg: {
                      container: 2,
                    },
                  }}}),_c('CheckboxElement',{attrs:{"name":"checkbox_60","text":"Hình ảnh","columns":{
                    default: {
                      container: 2,
                    },
                    lg: {
                      container: 2,
                    },
                  }}}),_c('CheckboxElement',{attrs:{"name":"checkbox_59","text":"Làm/thực hành","columns":{
                    default: {
                      container: 2,
                    },
                    lg: {
                      container: 2,
                    },
                  }}})],1),_c('GroupElement',{attrs:{"name":"container2_40","columns":{
                  lg: {
                    container: 12,
                  },
                }}},[_c('StaticElement',{attrs:{"name":"h4_39","tag":"p","content":"Rào cản/khó khăn trong học tập:","columns":{
                    default: {
                      container: 3,
                    },
                    lg: {
                      container: 4,
                    },
                  }}}),_c('GroupElement',{attrs:{"name":"container_27","columns":{
                    default: {
                      container: 9,
                    },
                    lg: {
                      container: 8,
                    },
                  }}},[_c('CheckboxElement',{attrs:{"name":"checkbox_50","columns":{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Rào cản ngôn ngữ "}}),_c('CheckboxElement',{attrs:{"name":"checkbox_56","columns":{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Giảm thị lực"}}),_c('CheckboxElement',{attrs:{"name":"checkbox_57","columns":{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Trình độ học vấn"}}),_c('CheckboxElement',{attrs:{"name":"checkbox_58","columns":{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Giảm thính lực"}}),_c('CheckboxElement',{attrs:{"name":"checkbox_62","columns":{
                      default: {
                        container: 4,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Khác biệt văn hóa "}}),_c('CheckboxElement',{attrs:{"name":"checkbox_61","columns":{
                      default: {
                        container: 3,
                      },
                      lg: {
                        container: 6,
                      },
                    },"text":"Khả năng sử dụng CN "}})],1),_c('TextElement',{attrs:{"name":"text_38","label":"Khác"}})],1)],1)],1),_c('GroupElement',{attrs:{"name":"column2_36","columns":{
              default: {
                container: 3,
              },
              lg: {
                container: 3,
              },
            }}},[_c('StaticElement',{attrs:{"name":"h4_35","tag":"h4","columns":{
                container: 12,
              },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_8"}})],1)],1):_vm._e(),_c('GroupElement',{attrs:{"name":"containerDinhDuong"}},[_c('StaticElement',{attrs:{"name":"h4_52","tag":"h4","content":"DINH DƯỠNG","columns":{
              container: 3,
            }}}),_c('div',{staticClass:"toggle-icon-container-dinhduong"},[_c('span',{on:{"click":_vm.toggleDinhDuong}},[_c('inline-svg',{attrs:{"src":_vm.isShowDinhDuong
                    ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                    : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)]),(_vm.isShowDinhDuong)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_28"}},[_c('GroupElement',{attrs:{"name":"column1_26","columns":{
                container: 9,
              }}},[_c('GroupElement',{attrs:{"name":"container2_29"}},[_c('GroupElement',{staticClass:"border-group",attrs:{"name":"column1_27","columns":{
                    container: 12,
                  }}},[_c('StaticElement',{attrs:{"name":"h4_55","tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn theo chế độ ăn uống lành mạnh?\n","columns":{
                      container: 9,
                    }}}),_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","name":"text_54","columns":{
                      container: 3,
                    }}})],1)],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_22"}},[_c('StaticElement',{attrs:{"name":"h4_57","tag":"p","content":"Trung bình trong THÁNG QUA, Có bao nhiêu ngày mỗi tuần Bạn ăn theo chế độ ăn của mình?","columns":{
                    container: 9,
                  }}}),_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","name":"text_55","columns":{
                    container: 3,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_23"}},[_c('StaticElement',{attrs:{"name":"h4_59","tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn 3 suất trái cây và hơn 3 suất rau củ?","columns":{
                    container: 9,
                  }}}),_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","name":"text_56","columns":{
                    container: 3,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_24"}},[_c('StaticElement',{attrs:{"name":"h4_61","tag":"p","content":"Trong vòng 7 NGÀY qua, Có bao nhiêu ngày Bạn ăn thức ăn có lượng béo cao như thịt đỏ, hoặc các sản phẩm từ sữa nguyên kem?","columns":{
                    container: 9,
                  }}}),_c('TextElement',{attrs:{"placeholder":"Nhập số ngày","name":"text_57","columns":{
                    container: 3,
                  }}})],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container_25"}},[_c('StaticElement',{attrs:{"name":"h4_63","tag":"h4","content":"Dị ứng thức ăn","columns":{
                    container: 12,
                  }}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_33"}},[_c('RadiogroupElement',{attrs:{"name":"problemFood","view":"tabs","items":[
                      {
                        value: 1,
                        label: 'Không',
                      },
                      {
                        value: 0,
                        label: 'Có',
                      },
                    ],"columns":{
                      container: 12,
                    }}})],1),_c('StaticElement',{attrs:{"name":"h4_64","tag":"h4","content":"Mô tả một ngày ăn uống","columns":{
                    container: 12,
                  }}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_34"}},[_c('StaticElement',{attrs:{"name":"h4_65","tag":"p","content":"Sáng","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}}),_c('StaticElement',{attrs:{"name":"h4_66","tag":"p","content":"Phụ 1","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}}),_c('StaticElement',{attrs:{"name":"h4_67","tag":"p","content":"Trưa","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}}),_c('StaticElement',{attrs:{"name":"h4_68","tag":"p","content":"Phụ 2","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}}),_c('StaticElement',{attrs:{"name":"h4_69","tag":"p","content":"Chiều","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}}),_c('StaticElement',{attrs:{"name":"h4_70","tag":"p","content":"Phụ 3","columns":{
                      container: 3,
                    }}}),_c('TextElement',{attrs:{"name":"text_58","columns":{
                      container: 9,
                    }}})],1),_c('StaticElement',{attrs:{"name":"h4_71","tag":"h4","content":"Thói quen ăn uống ","columns":{
                    container: 12,
                  }}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_35"}},[_c('GroupElement',{attrs:{"name":"column1_33"}},[_c('StaticElement',{attrs:{"name":"h4_72","tag":"p","content":"Số bữa chính","columns":{
                        container: 2,
                      }}}),_c('TextElement',{attrs:{"name":"text_63","columns":{
                        container: 9,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_73","tag":"p","content":"Số bữa phụ","columns":{
                        container: 2,
                      }}}),_c('TextElement',{attrs:{"name":"text_64","columns":{
                        container: 9,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_74","tag":"p","content":"Ăn vặt","columns":{
                        container: 4,
                      }}}),_c('RadiogroupElement',{attrs:{"name":"sleepingProblem","view":"tabs","items":[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ],"columns":{
                        container: 7,
                      }}}),_c('TextElement',{attrs:{"placeholder":"Ghi chú ghi rõ món ăn vặt","name":"text_65","columns":{
                        container: 12,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_75","tag":"p","content":"Ăn đúng giờ","columns":{
                        container: 4,
                      }}}),_c('RadiogroupElement',{attrs:{"name":"sleepingProblem","view":"tabs","items":[
                        {
                          value: 1,
                          label: 'Không',
                        },
                        {
                          value: 0,
                          label: 'Có',
                        },
                      ],"columns":{
                        container: 7,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_76","tag":"p","content":"Ăn ngoài","columns":{
                        container: 4,
                      }}}),_c('RadiogroupElement',{attrs:{"name":"sleepingProblem","view":"tabs","items":[
                        {
                          value: 0,
                          label: 'Không',
                        },
                        {
                          value: 1,
                          label: 'Thỉnh thoảng',
                        },
                        {
                          value: 2,
                          label: 'Thường xuyên',
                        },
                      ],"columns":{
                        container: 8,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_76","tag":"p","content":"Ăn ngoài","columns":{
                        container: 4,
                      }}}),_c('RadiogroupElement',{attrs:{"name":"sleepingProblem","view":"tabs","items":[
                        {
                          value: 0,
                          label: 'Không',
                        },
                        {
                          value: 1,
                          label: 'Thỉnh thoảng',
                        },
                        {
                          value: 2,
                          label: 'Thường xuyên',
                        },
                      ],"columns":{
                        container: 8,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_78","tag":"p","content":"Lượng nước uống trong ngày ( lít/ngày\n )","columns":{
                        container: 6,
                      }}}),_c('TextElement',{attrs:{"name":"text_66","columns":{
                        container: 6,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_79","tag":"p","content":"Loại nước uống trong ngày :","columns":{
                        container: 6,
                      }}}),_c('TextElement',{attrs:{"name":"text_67","columns":{
                        container: 6,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_80","tag":"p","content":"Mức kcal/ngày theo khuyến nghị ","columns":{
                        container: 6,
                      }}}),_c('TextElement',{attrs:{"name":"text_68","columns":{
                        container: 6,
                      }}}),_c('StaticElement',{attrs:{"name":"h4_81","tag":"p","content":"Nguyên tắc Thực đơn","columns":{
                        container: 6,
                      }}}),_c('CheckboxElement',{attrs:{"name":"checkbox_76","text":"Gửi thực đơn cho KH ","columns":{
                        container: 6,
                      }}}),_c('TextElement',{attrs:{"name":"text_69","label":"Khác"}})],1)],1)],1)],1),_c('GroupElement',{attrs:{"name":"column2_45","columns":{
                container: 3,
              }}},[_c('StaticElement',{attrs:{"name":"h4_54","tag":"h4","columns":{
                  container: 12,
                },"content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('EditorElement',{attrs:{"name":"textarea_6"}})],1)],1):_vm._e()],1),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerDLVMT"}},[_c('StaticElement',{attrs:{"name":"h2","tag":"h4","content":"ĐỘNG LỰC "}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerDL"}},[_c('EditorElement',{attrs:{"name":"textarea_9"}})],1),_c('StaticElement',{attrs:{"name":"divider_DLMT","tag":"hr"}}),_c('StaticElement',{attrs:{"name":"h2","tag":"h4","content":"TRỞ NGẠI (KHÓ KHĂN) (khi tham gia chương trình và mục tiêu đạt được)"}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerTN"}},[_c('EditorElement',{attrs:{"name":"textarea_9"}})],1),_c('StaticElement',{attrs:{"name":"divider_DLMT","tag":"hr"}}),_c('StaticElement',{attrs:{"name":"h2","tag":"h4","content":"MỨC ĐỘ TỰ TIN"}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerMDTT"}},[_c('EditorElement',{attrs:{"name":"textarea_9"}})],1),_c('StaticElement',{attrs:{"name":"divider_DLMT","tag":"hr"}}),_c('StaticElement',{attrs:{"name":"h2","tag":"h4","content":"VẤN ĐỀ CỦA KHÁCH HÀNG"}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"containerVDKH"}},[_c('EditorElement',{attrs:{"name":"textarea_9"}})],1),_c('StaticElement',{attrs:{"name":"divider_DLMT","tag":"hr"}}),_c('GroupElement',{attrs:{"name":"containerMuctieu","columns":{
              container: 12,
            }}},[_c('StaticElement',{attrs:{"name":"title_containerMuctieu","tag":"h4","content":"MỤC TIÊU","columns":{
                container: 2,
              }}}),_c('div',{staticClass:"toggle-icon-container-muctieu"},[_c('span',{on:{"click":_vm.toggleMuctieu}},[_c('inline-svg',{attrs:{"src":_vm.isShowMuctieu
                      ? '/media/svg/icons/Neolex/Basic/circle-minus.svg'
                      : '/media/svg/icons/Neolex/Basic/circle-plus.svg'}})],1)])],1),(_vm.isShowMuctieu)?_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_41"}},[_c('StaticElement',{attrs:{"name":"h2_6","tag":"h4","content":"MỤC TIÊU CHƯƠNG TRÌNH"}}),_c('GroupElement',{staticClass:"border-group",attrs:{"name":"container2_445"}},[_c('GroupElement',{attrs:{"name":"container_hba1c"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"h2_6","tag":"p","content":"HbA1c","columns":{
                    container: 2,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"optionsHba1c","view":"tabs","items":[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ],"columns":{
                    container: 3,
                  }}}),_c('TextElement',{attrs:{"name":"hbA1cInput","placeholder":"%","input-type":"number","columns":{
                    container: 1,
                  }}})],1),_c('GroupElement',{attrs:{"name":"container_weight"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"h2_6","tag":"p","content":"Cân nặng","columns":{
                    container: 2,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"optionsWeight","view":"tabs","items":[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ],"columns":{
                    container: 3,
                  }}}),_c('TextElement',{attrs:{"name":"weightInput","placeholder":"kg","input-type":"number","columns":{
                    container: 1,
                  }}})],1),_c('GroupElement',{attrs:{"name":"container_knowledge"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"h2_6","tag":"p","content":"Kiến thức","columns":{
                    container: 2,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"optionsKnowledge","view":"tabs","items":[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ],"columns":{
                    container: 3,
                  }}}),_c('TextElement',{attrs:{"name":"yearOfBirth","placeholder":"điểm","input-type":"number","columns":{
                    container: 1,
                  }}})],1),_c('GroupElement',{attrs:{"name":"container_kntcs"}},[_c('StaticElement',{staticClass:"labeltext",attrs:{"name":"h2_6","tag":"p","content":"Khả năng tự CS","columns":{
                    container: 2,
                  }}}),_c('RadiogroupElement',{attrs:{"name":"optionsKntcs","view":"tabs","items":[
                    {
                      value: 0,
                      label: 'Thấp hơn',
                    },
                    {
                      value: 1,
                      label: 'Duy trì',
                    },
                    {
                      value: 2,
                      label: 'Cao hơn',
                    },
                  ],"columns":{
                    container: 3,
                  }}}),_c('TextElement',{attrs:{"name":"yearOfBirth","placeholder":"điểm","input-type":"number","columns":{
                    container: 1,
                  }}})],1)],1),_c('StaticElement',{attrs:{"name":"h2_6","tag":"h4","content":"MỤC TIÊU CỤ THỂ"}}),_c('MatrixElement',{attrs:{"name":"table_3","input-type":{
                type: 'text',
              },"presets":['matrix-table'],"cols":[
                {
                  label: 'Mục tiêu thay đổi lối sống',
                  value: 'mục_tiêu_thay_đổi_lối_sống',
                },
                {
                  label: 'Kế hoạch hành động ',
                  value: 'kế_hoạch_hành_động_',
                },
              ],"rows":1,"min":"1","max":"10"}}),_c('MatrixElement',{attrs:{"name":"table","input-type":{
                type: 'text',
              },"presets":['matrix-table'],"cols":[
                {
                  label: 'Mục tiêu thay đổi lối sống',
                  value: 'mục_tiêu_thay_đổi_lối_sống',
                },
                {
                  label: 'Kế hoạch hành động ',
                  value: 'kế_hoạch_hành_động_',
                },
              ],"rows":"1","min":"1","max":"10"}})],1):_vm._e(),_c('GroupElement',{attrs:{"name":"container2_44"}},[_c('GroupElement',{attrs:{"name":"container2_46","columns":{
                lg: {
                  container: 12,
                },
              }}},[_c('StaticElement',{attrs:{"name":"divider_DLMT","tag":"hr"}}),_c('StaticElement',{attrs:{"name":"h2_1","tag":"h4","content":"HÀNH ĐỘNG"}}),_c('EditorElement',{attrs:{"name":"editor"}}),_c('MatrixElement',{attrs:{"name":"table_1","input-type":{
                  type: 'text',
                },"presets":['matrix-table'],"cols":[
                  {
                    label: 'Mục tiêu thay đổi lối sống',
                    value: 'mục_tiêu_thay_đổi_lối_sống',
                  },
                  {
                    label: 'Kế hoạch hành động ',
                    value: 'kế_hoạch_hành_động_',
                  },
                ],"rows":"1","min":"1","max":"10"}})],1)],1),_c('GroupElement',{attrs:{"name":"container2_47"}},[_c('GroupElement',{attrs:{"name":"container2_48","columns":{
                lg: {
                  container: 12,
                },
              }}},[_c('StaticElement',{attrs:{"name":"h2_4","tag":"h4","content":"NHẬN XÉT CHUNG\n"}}),_c('EditorElement',{attrs:{"name":"editor_1"}}),_c('MatrixElement',{attrs:{"name":"table_2","input-type":{
                  type: 'text',
                },"presets":['matrix-table'],"cols":[
                  {
                    label: 'Mục tiêu thay đổi lối sống',
                    value: 'mục_tiêu_thay_đổi_lối_sống',
                  },
                  {
                    label: 'Kế hoạch hành động ',
                    value: 'kế_hoạch_hành_động_',
                  },
                ],"rows":"1","min":"1","max":"10"}})],1)],1)],1)],1),_c('FormStepsControls')]},proxy:true}]),model:{value:(_vm.formCommon.formPage),callback:function ($$v) {_vm.$set(_vm.formCommon, "formPage", $$v)},expression:"formCommon.formPage"}})
}
var staticRenderFns = []

export { render, staticRenderFns }