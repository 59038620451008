<template>
  <b-modal
    id="form-modal"
    size="xl"
    centered
    hide-footer
    @hidden="resetModal"
    @close="resetModal"
  >
    <CoachComment :name="commentName" />
  
  </b-modal>
</template>

<script>
import CoachComment from '@/view/pages/coaches_calendar/components/CoachCommentPage.vue'

export default {
  name: 'FormModal',
  components: {
    CoachComment,
  },
  data() {
    return {
      commentName: 'uniqueCommentName', // Provide a value for the name prop
    }
  },
  methods: {
    resetModal() {
      this.$bvModal.hide('form-modal');
    },

  },
}
</script>

